.desktop {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}

.desktop .header-navigation {
  align-items: center;
  width: 100%; /* Make sure it stretches fully */
  background-color: var(--basewhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0px 4px;
  position: relative;
  width: 1224px;
}
.desktop .nav-content-wrapper { /* New wrapper class */
  max-width: 1224px;
  margin: 0 auto; /* Center the content */
}

.desktop .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 72px;
  justify-content: space-between;
  max-width: 1280px;
  position: relative;
  width: 100%;
}

.desktop .content {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.desktop .logo {
  height: 24px;
  position: relative;
  width: 116px;
}

.desktop .vector {
  height: 19px;
  left: 36px;
  position: absolute;
  top: 3px;
  width: 78px;
}

.desktop .logomark-instance {
  height: 17px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 25px !important;
}

.desktop .div-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.desktop .nav-item-base {
  align-items: center;
  background-color: var(--gray-50);
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}

.desktop .div-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.desktop .bar-chart {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.desktop .text-5 {
  color: #121315;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .content-wrapper {
  align-items: center;
  background-color: var(--basewhite);
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease;
}

.desktop .design-component-instance-node {
  position: relative !important;
}

.desktop .dropdown {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  position: relative;
  width: 40px;
}

.desktop .avatar-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f9fafb;
  border-radius: 200px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}

.desktop .text-wrapper-2 {
  color: #000000;
  font-family: "Aeonik-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 13px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.desktop .contrast-border-2 {
  align-self: stretch;
  border: 0.75px solid;
  border-color: #121315;
  border-radius: 200px;
  flex: 1;
  flex-grow: 1;
  opacity: 0.08;
  position: relative;
}

.desktop .main {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 48px 0px 96px;
  position: relative;
}

.desktop .container-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.desktop .page-header-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  max-width: 1280px;
  position: relative;
}

.desktop .page-header {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.desktop .content-2 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 16px 20px;
  justify-content: center;
  position: relative;
  width: 1216px;
}

.desktop .text-and-supporting {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  min-width: 320px;
  position: relative;
}

.desktop .text-6 {
  align-self: stretch;
  color: #121315;
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.desktop .supporting-text {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  position: relative;
}

.desktop .date-picker-dropdown {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.desktop .button-group {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 8px;
  box-shadow: var(--shadows-shadow-xs);
  display: inline-flex;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;
}

.desktop .button-group-base {
  align-items: center;
  background-color: #fff;
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  z-index: 3;
}

.desktop .text-7 {
  color: var(--gray-800);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .div-wrapper {
  align-items: center;
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.2s ease;
  z-index: 2;
}

.desktop .text-8 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .button-group-base-2 {
  align-items: center;
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.2s ease;
  z-index: 1;
}

.desktop .button-group-base-3 {
  align-items: center;
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.2s ease;
  z-index: 0;
}

.desktop .button {
  all: unset;
  align-items: center;
  background-color: #121315;
  border-radius: 8px;
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 14px;
  position: relative;
  transition: all 0.2s ease;
}
.desktop .button:hover {
  background-color: #404143;
}

.desktop .arrow-left {
  height: 20px;
  position: relative;
  width: 20px;
}

.desktop .text-padding {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 2px;
  position: relative;
}
.button-group-base.active,
.div-wrapper.active,
.button-group-base-2.active,
.button-group-base-3.active {
    background-color: #F9FAFB; 
}
.button-group-base:hover,
.div-wrapper:hover,
.button-group-base-2:hover,
.button-group-base-3:hover {
    background-color: #F9FAFB; 
}
.desktop .text-9 {
  color: var(--basewhite);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .divider {
  height: 1px;
  position: relative;
  width: 1216px;
}

.desktop .sections-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  justify-content: center;
  max-width: 1280px;
  position: relative;
}

.desktop .sections {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 1224px;
}

.desktop .section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.desktop .content-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: center;
  position: relative;
}

.desktop .metric-item {
  align-items: flex-start;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  box-shadow: var(--shadows-shadow-xs);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  position: relative;
  transition: all 0.2s ease;
  width: 388px;
}
.desktop .metric-item:hover {
  background-color: #F9FAFB; 
}
.desktop .featured-icon {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 10px;
  box-shadow: var(--shadows-shadow-xs);
  height: 48px;
  position: relative;
  width: 48px;
}

.desktop .img {
  height: 24px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 24px;
}

.desktop .heading-and-number {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.desktop .heading {
  align-self: stretch;
  color: #475466;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  position: relative;
}

.desktop .number-and-badge {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 16px 12px;
  position: relative;
  width: 100%;
}

.desktop .number {
  color: #121315;
  font-family: "Inter", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .number-2 {
  color: #121315;
  font-family: var(--display-md-semibold-font-family);
  font-size: var(--display-md-semibold-font-size);
  font-style: var(--display-md-semibold-font-style);
  font-weight: var(--display-md-semibold-font-weight);
  letter-spacing: var(--display-md-semibold-letter-spacing);
  line-height: var(--display-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .table {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  box-shadow: var(--shadows-shadow-sm);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1216px;
}

.desktop .card-header {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--basewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.desktop .text-and-supporting-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  padding: 20px 24px 0px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.desktop .text-and-supporting-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.desktop .text-and-badge {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.desktop .text-10 {
  color: var(--gray-900);
  font-family: var(--text-lg-semibold-font-family);
  font-size: var(--text-lg-semibold-font-size);
  font-style: var(--text-lg-semibold-font-style);
  font-weight: var(--text-lg-semibold-font-weight);
  letter-spacing: var(--text-lg-semibold-letter-spacing);
  line-height: var(--text-lg-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .badge-instance {
  background-color: #0077ff1a !important;
  border-color: #0077ff33 !important;
  flex: 0 0 auto !important;
}

.desktop .badge-2 {
  color: #0077ff !important;
}

.desktop .p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--gray-600);
  display: -webkit-box;
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.desktop .divider-2 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.desktop .content-4 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.desktop .column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 220px;
}

.desktop .table-header-cell {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: flex;
  gap: 12px;
  height: 44px;
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.desktop .table-header-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.desktop .text-11 {
  color: var(--gray-600);
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .table-cell {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: flex;
  gap: 12px;
  height: 72px;
  padding: 16px 24px;
  position: relative;
  width: 100%;
}

.desktop .ellipse {
  height: 40px;
  object-fit: cover;
  position: relative;
  width: 40px;
}

.desktop .div-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.desktop .text-12 {
  color: var(--gray-900);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .supporting-text-2 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .column-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 68px;
}

.desktop .table-header-cell-2 {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  height: 44px;
  position: relative;
  width: 68px;
}

.desktop .arrow-left-wrapper {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: inline-flex;
  height: 72px;
  padding: 16px 24px;
  position: relative;
}

.desktop .table-header-cell-3 {
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  height: 44px;
  position: relative;
  width: 100%;
}

.desktop .column-3 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.desktop .table-header-instance {
  flex: 0 0 auto !important;
}

.desktop .table-cell-2 {
  align-items: center;
  align-self: stretch;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: flex;
  height: 72px;
  padding: 16px 24px;
  position: relative;
  width: 100%;
}

.desktop .text-and-supporting-3 {
  align-items: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.desktop .text-13 {
  color: var(--gray-900);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .table-cell-3 {
  align-self: stretch;
  height: 72px;
  position: relative;
  width: 100%;
}

.desktop .pagination {
  align-items: center;
  align-self: stretch;
  border-color: var(--gray-200);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  margin-left: -1px;
  margin-right: -1px;
  padding: 12px 24px 16px;
  position: relative;
  width: 100%;
}

.desktop .details {
  color: var(--gray-700);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.desktop .actions {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.desktop .buttons-button {
  all: unset;
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 8px;
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease;
}
.desktop .buttons-button:hover {
  background-color: #F9FAFB;
}

.desktop .text-14 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
.mini-chart {
  height: 50px;
  width: 100%;
}
/* Add this to your style.css */

.skeleton {
  background: linear-gradient(90deg, #f0f0f0, #f8f8f8, #f0f0f0);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #EAECF0;
  background-color: #fff;
  border-radius: 8px;
  width: 150px;
  box-shadow: 0 2px 8px rgba(16, 24, 40, 0.05);
  z-index: 1000; /* Ensure it's on top of other content */
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 16px;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f9fafb;
}
.no-transactions {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100px; /* Adjust the height as needed */
  grid-column: 1 / -1; /* Span full width if using CSS Grid */
  font-family: 'Inter', sans-serif; /* Use Inter font */
  width: 100%; /* Ensure full width */
  padding: 2rem; /* Add some padding */
  box-sizing: border-box; /* Include padding in width and height calculations */
  font-weight: 400;
}
.no-transactions-message {
  grid-column: 1 / -1; /* This will make the message span all columns */
  text-align: center; /* Center the text horizontally */
  align-self: center; /* Center the text vertically if your grid is configured for aligning items */
  padding: 20px; /* Add some padding */
  font-family: 'Inter', sans-serif; /* Use the Inter font */
  font-weight: 400;
}
.no-transactions-container {
  display: grid;
  place-items: center;
  grid-column: 1 / -1; /* Span across all columns */
  height: 100px; /* Or any other height you prefer */
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

