.dot .div {
  background-color: var(--success-500);
  position: relative;
}

.dot.md {
  height: 10px;
  width: 10px;
}

.dot.lg {
  height: 12px;
  width: 12px;
}

.dot.sm {
  height: 8px;
  width: 8px;
}

.dot .outline-false {
  left: 1px;
  top: 1px;
}

.dot .outline-true {
  border-color: var(--success-100);
}

.dot .size-lg.outline-true {
  border: 5px solid;
  border-radius: 10px;
  height: 20px;
  left: -4px;
  top: -4px;
  width: 20px;
}

.dot .size-lg.outline-false {
  border-radius: 5px;
  height: 10px;
  width: 10px;
}

.dot .outline-true.size-md {
  border: 4px solid;
  border-radius: 8px;
  height: 16px;
  left: -3px;
  top: -3px;
  width: 16px;
}

.dot .outline-false.size-md {
  border-radius: 4px;
  height: 8px;
  width: 8px;
}

.dot .outline-true.size-sm {
  border: 3px solid;
  border-radius: 6px;
  height: 12px;
  left: -2px;
  top: -2px;
  width: 12px;
}

.dot .outline-false.size-sm {
  border-radius: 3px;
  height: 6px;
  width: 6px;
}
