.badge {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.badge .text {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.badge .class {
  background-color: var(--brand-500) !important;
}

.badge .class-2 {
  background-color: var(--brand-600) !important;
}

.badge .class-3 {
  background-color: var(--warning-500) !important;
}

.badge .class-4 {
  background-color: var(--warning-600) !important;
}

.badge .class-5 {
  background-color: var(--success-600) !important;
}

.badge .class-6 {
  background-color: var(--gray-blue500) !important;
}

.badge .class-7 {
  background-color: var(--blue-light500) !important;
}

.badge .class-8 {
  background-color: var(--indigo-500) !important;
}

.badge .class-9 {
  background-color: var(--purple-500) !important;
}

.badge .class-10 {
  background-color: var(--blue-500) !important;
}

.badge .class-11 {
  background-color: var(--pink-500) !important;
}

.badge .class-12 {
  background-color: var(--orange-500) !important;
}

.badge .class-13 {
  background-color: var(--error-500) !important;
}

.badge .class-14 {
  background-color: var(--error-600) !important;
}

.badge .class-15 {
  background-color: var(--gray-500) !important;
}

.badge .class-16 {
  background-color: var(--gray-600) !important;
}

.badge .dot-instance {
  position: relative !important;
}

.badge .text-2 {
  position: relative;
}

.badge .contrast-border {
  border: 0.33px solid;
  border-color: var(--baseblack);
  border-radius: 133.33px;
  height: 16px;
  opacity: 0.08;
}

.badge .badge-close-x {
  position: relative;
}

.badge .instance-node {
  height: 12px !important;
  position: relative !important;
  width: 12px !important;
}

.badge .text-wrapper {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.badge .AU {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.badge .text-3 {
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.badge.icon-icon-trailing {
  gap: 4px;
}

.badge.icon-icon-leading {
  gap: 4px;
}

.badge.pill-color {
  border: 1px solid;
  border-radius: 16px;
}

.badge.icon-x-close {
  gap: 2px;
}

.badge.pill-outline {
  border: 1.5px solid;
  border-radius: 16px;
}

.badge.gray-blue {
  background-color: var(--gray-blue50);
  border-color: var(--gray-blue200);
}

.badge.badge-modern {
  border: 1px solid;
  box-shadow: var(--shadows-shadow-xs);
}

.badge.badge-color {
  border: 1px solid;
}

.badge.icon-only {
  gap: 4px;
}

.badge.badge-color.warning {
  background-color: var(--warning-50);
  border-color: var(--warning-200);
}

.badge.size-0-lg.badge-color {
  border-radius: 8px;
}

.badge.pill-outline.blue-light {
  border-color: var(--blue-light600);
}

.badge.badge-color.pink {
  background-color: var(--pink-50);
  border-color: var(--pink-200);
}

.badge.pill-outline.gray {
  border-color: var(--gray-600);
}

.badge.blue.pill-color {
  background-color: var(--blue-50);
  border-color: var(--blue-200);
}

.badge.size-0-lg.icon-country {
  gap: 6px;
}

.badge.pill-outline.error {
  border-color: var(--error-600);
}

.badge.badge-modern.warning {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.blue-light.pill-color {
  background-color: var(--blue-light50);
  border-color: var(--blue-light200);
}

.badge.pill-color.warning {
  background-color: var(--warning-50);
  border-color: var(--warning-200);
}

.badge.blue-light.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.success {
  background-color: var(--success-50);
  border-color: var(--success-200);
}

.badge.pill-outline.warning {
  border-color: var(--warning-600);
}

.badge.badge-color.blue-light {
  background-color: var(--blue-light50);
  border-color: var(--blue-light200);
}

.badge.pill-outline.pink {
  border-color: var(--pink-600);
}

.badge.pill-outline.blue {
  border-color: var(--blue-600);
}

.badge.badge-color.brand {
  background-color: var(--brand-50);
  border-color: var(--brand-200);
}

.badge.pill-color.orange {
  background-color: var(--orange-50);
  border-color: var(--orange-200);
}

.badge.success.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.pink.pill-color {
  background-color: var(--pink-50);
  border-color: var(--pink-200);
}

.badge.badge-color.indigo {
  background-color: var(--indigo-50);
  border-color: var(--indigo-200);
}

.badge.badge-color.size-0-sm {
  border-radius: 6px;
}

.badge.size-0-md.icon-country {
  gap: 6px;
}

.badge.pill-outline.blue-gray {
  border-color: var(--gray-blue600);
}

.badge.size-0-md.icon-only {
  padding: 6px;
}

.badge.size-0-sm.badge-modern {
  border-radius: 6px;
}

.badge.indigo.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.blue {
  background-color: var(--blue-50);
  border-color: var(--blue-200);
}

.badge.size-0-sm.icon-country {
  gap: 4px;
}

.badge.gray.pill-color {
  background-color: var(--gray-50);
  border-color: var(--gray-200);
}

.badge.badge-modern.orange {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.pill-outline.purple {
  border-color: var(--purple-600);
}

.badge.size-0-lg.icon-only {
  padding: 8px;
}

.badge.gray.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.icon-avatar.size-0-sm {
  gap: 4px;
}

.badge.blue-gray.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.error {
  background-color: var(--error-50);
  border-color: var(--error-200);
}

.badge.success.pill-color {
  background-color: var(--success-50);
  border-color: var(--success-200);
}

.badge.error.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.blue-gray {
  background-color: var(--gray-blue50);
  border-color: var(--gray-blue200);
}

.badge.indigo.pill-color {
  background-color: var(--indigo-50);
  border-color: var(--indigo-200);
}

.badge.error.pill-color {
  background-color: var(--error-50);
  border-color: var(--error-200);
}

.badge.badge-color.orange {
  background-color: var(--orange-50);
  border-color: var(--orange-200);
}

.badge.size-0-md.badge-modern {
  border-radius: 6px;
}

.badge.brand.pill-color {
  background-color: var(--brand-50);
  border-color: var(--brand-200);
}

.badge.size-0-lg.icon-avatar {
  gap: 6px;
}

.badge.badge-color.purple {
  background-color: var(--purple-50);
  border-color: var(--purple-200);
}

.badge.purple.pill-color {
  background-color: var(--purple-50);
  border-color: var(--purple-200);
}

.badge.icon-dot.size-0-md {
  gap: 6px;
}

.badge.blue.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.icon-avatar.size-0-md {
  gap: 6px;
}

.badge.pink.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.size-0-lg.badge-modern {
  border-radius: 8px;
}

.badge.pill-outline.indigo {
  border-color: var(--indigo-600);
}

.badge.pill-outline.success {
  border-color: var(--success-600);
}

.badge.size-0-lg.icon-dot {
  gap: 6px;
}

.badge.brand.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.size-0-sm.icon-only {
  padding: 5px;
}

.badge.pill-outline.orange {
  border-color: var(--orange-600);
}

.badge.purple.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.gray {
  background-color: var(--gray-50);
  border-color: var(--gray-200);
}

.badge.pill-outline.brand {
  border-color: var(--brand-600);
}

.badge.badge-color.size-0-md {
  border-radius: 6px;
}

.badge.size-0-sm.icon-dot {
  gap: 4px;
}

.badge.size-0-lg.icon-dot.pill-color {
  padding: 4px 12px 4px 10px;
}

.badge.pill-outline.size-0-sm.icon-dot {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.badge-color.icon-icon-trailing {
  padding: 4px 8px 4px 10px;
}

.badge.badge-color.icon-avatar.size-0-sm {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-sm.badge-modern.icon-x-close {
  padding: 2px 3px 2px 6px;
}

.badge.size-0-sm.icon-dot.badge-modern {
  padding: 2px 6px;
}

.badge.size-0-sm.icon-country.pill-color {
  padding: 2px 8px 2px 3px;
}

.badge.badge-color.size-0-md.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.size-0-md.pill-color.icon-x-close {
  padding: 2px 4px 2px 10px;
}

.badge.size-0-lg.badge-color.icon-country {
  padding: 4px 10px 4px 8px;
}

.badge.size-0-md.icon-country.badge-modern {
  padding: 2px 8px 2px 6px;
}

.badge.badge-color.icon-avatar.size-0-md {
  padding: 2px 8px 2px 6px;
}

.badge.badge-color.size-0-sm.icon-icon-trailing {
  padding: 2px 4px 2px 6px;
}

.badge.size-0-lg.icon-avatar.pill-outline {
  padding: 4px 12px 4px 6px;
}

.badge.size-0-lg.badge-color.icon-dot {
  padding: 4px 10px;
}

.badge.size-0-md.icon-country.pill-color {
  padding: 2px 10px 2px 4px;
}

.badge.size-0-lg.icon-false.badge-modern {
  padding: 4px 10px;
}

.badge.size-0-lg.pill-outline.icon-icon-trailing {
  padding: 4px 10px 4px 12px;
}

.badge.size-0-sm.pill-color.icon-x-close {
  padding: 2px 3px 2px 8px;
}

.badge.pill-outline.icon-avatar.size-0-sm {
  padding: 2px 8px 2px 3px;
}

.badge.pill-outline.size-0-sm.icon-x-close {
  padding: 2px 3px 2px 8px;
}

.badge.size-0-lg.pill-color.icon-icon-trailing {
  padding: 4px 10px 4px 12px;
}

.badge.size-0-lg.badge-color.icon-false {
  padding: 4px 10px;
}

.badge.size-0-sm.icon-icon-leading.pill-color {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.pill-outline.icon-x-close {
  padding: 4px 6px 4px 12px;
}

.badge.size-0-md.icon-false.badge-modern {
  padding: 2px 8px;
}

.badge.badge-color.size-0-sm.icon-country {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-lg.icon-dot.pill-outline {
  padding: 4px 12px 4px 10px;
}

.badge.size-0-lg.icon-country.pill-outline {
  padding: 4px 12px 4px 6px;
}

.badge.size-0-lg.icon-country.badge-modern {
  padding: 4px 10px 4px 8px;
}

.badge.icon-avatar.size-0-md.pill-color {
  padding: 2px 10px 2px 4px;
}

.badge.pill-outline.size-0-sm.icon-icon-leading {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.pill-color.icon-x-close {
  padding: 4px 6px 4px 12px;
}

.badge.badge-color.size-0-sm.icon-dot {
  padding: 2px 6px;
}

.badge.badge-color.size-0-sm.icon-x-close {
  padding: 2px 3px 2px 6px;
}

.badge.size-0-lg.badge-color.icon-icon-leading {
  padding: 4px 10px 4px 8px;
}

.badge.size-0-lg.icon-false.pill-outline {
  padding: 4px 12px;
}

.badge.pill-outline.size-0-md.icon-icon-leading {
  padding: 2px 10px 2px 8px;
}

.badge.size-0-lg.icon-avatar.badge-color {
  padding: 4px 10px 4px 8px;
}

.badge.pill-outline.size-0-md.icon-x-close {
  padding: 2px 4px 2px 10px;
}

.badge.size-0-md.pill-color.icon-icon-trailing {
  padding: 2px 8px 2px 10px;
}

.badge.size-0-lg.icon-country.pill-color {
  padding: 4px 12px 4px 6px;
}

.badge.pill-outline.icon-avatar.size-0-md {
  padding: 2px 10px 2px 4px;
}

.badge.pill-outline.size-0-md.icon-false {
  padding: 2px 10px;
}

.badge.badge-color.size-0-md.icon-icon-leading {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-sm.icon-dot.pill-color {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.icon-icon-leading.badge-modern {
  padding: 4px 10px 4px 8px;
}

.badge.size-0-lg.badge-modern.icon-icon-trailing {
  padding: 4px 8px 4px 10px;
}

.badge.size-0-lg.icon-icon-leading.pill-outline {
  padding: 4px 12px 4px 10px;
}

.badge.badge-color.size-0-sm.icon-icon-leading {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-lg.icon-icon-leading.pill-color {
  padding: 4px 12px 4px 10px;
}

.badge.pill-outline.size-0-sm.icon-false {
  padding: 2px 8px;
}

.badge.size-0-lg.icon-avatar.pill-color {
  padding: 4px 12px 4px 6px;
}

.badge.size-0-sm.icon-country.badge-modern {
  padding: 2px 6px 2px 4px;
}

.badge.pill-outline.size-0-md.icon-country {
  padding: 2px 10px 2px 4px;
}

.badge.pill-outline.icon-dot.size-0-md {
  padding: 2px 10px 2px 8px;
}

.badge.icon-dot.size-0-md.badge-modern {
  padding: 2px 8px;
}

.badge.icon-dot.size-0-md.pill-color {
  padding: 2px 10px 2px 8px;
}

.badge.size-0-md.badge-modern.icon-x-close {
  padding: 2px 4px 2px 8px;
}

.badge.size-0-lg.icon-dot.badge-modern {
  padding: 4px 10px;
}

.badge.size-0-sm.pill-color.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.size-0-md.icon-icon-leading.badge-modern {
  padding: 2px 8px 2px 6px;
}

.badge.pill-outline.size-0-md.icon-icon-trailing {
  padding: 2px 8px 2px 10px;
}

.badge.icon-avatar.size-0-sm.pill-color {
  padding: 2px 8px 2px 3px;
}

.badge.icon-avatar.size-0-sm.badge-modern {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-lg.icon-false.pill-color {
  padding: 4px 12px;
}

.badge.badge-modern.size-0-md.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.badge-color.size-0-md.icon-false {
  padding: 2px 8px;
}

.badge.size-0-lg.icon-avatar.badge-modern {
  padding: 4px 10px 4px 8px;
}

.badge.pill-outline.size-0-sm.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.size-0-md.icon-false.pill-color {
  padding: 2px 10px;
}

.badge.icon-avatar.size-0-md.badge-modern {
  padding: 2px 8px 2px 6px;
}

.badge.badge-color.size-0-md.icon-x-close {
  padding: 2px 4px 2px 8px;
}

.badge.size-0-sm.icon-icon-leading.badge-modern {
  padding: 2px 6px 2px 4px;
}

.badge.badge-color.icon-dot.size-0-md {
  padding: 2px 8px;
}

.badge.size-0-sm.icon-false.pill-color {
  padding: 2px 8px;
}

.badge.badge-color.size-0-sm.icon-false {
  padding: 2px 6px;
}

.badge.badge-modern.size-0-sm.icon-icon-trailing {
  padding: 2px 4px 2px 6px;
}

.badge.size-0-lg.badge-color.icon-x-close {
  padding: 4px 6px 4px 10px;
}

.badge.size-0-md.icon-icon-leading.pill-color {
  padding: 2px 10px 2px 8px;
}

.badge.size-0-lg.badge-modern.icon-x-close {
  padding: 4px 6px 4px 10px;
}

.badge.pill-outline.size-0-sm.icon-country {
  padding: 2px 8px 2px 3px;
}

.badge.badge-color.size-0-md.icon-country {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-sm.icon-false.badge-modern {
  padding: 2px 6px;
}

.badge.gray .text {
  color: var(--gray-700);
}

.badge.size-0-sm .text {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.warning .text {
  color: var(--warning-700);
}

.badge.pill-color .text {
  margin-top: -1px;
}

.badge.purple .text {
  color: var(--purple-700);
}

.badge.blue .text {
  color: var(--blue-700);
}

.badge.brand .text {
  color: var(--brand-700);
}

.badge.pill-outline .text {
  margin-top: -1.5px;
}

.badge.error .text {
  color: var(--error-700);
}

.badge.blue-light .text {
  color: var(--blue-light700);
}

.badge.indigo .text {
  color: var(--indigo-700);
}

.badge.gray-blue .text {
  color: var(--gray-blue700);
}

.badge.size-0-md .text {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-modern .text {
  margin-top: -1px;
}

.badge.size-0-lg .text {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.pink .text {
  color: var(--pink-700);
}

.badge.badge-color .text {
  margin-top: -1px;
}

.badge.orange .text {
  color: var(--orange-700);
}

.badge.success .text {
  color: var(--success-700);
}

.badge.blue-gray .text {
  color: var(--gray-blue700);
}

.badge.icon-icon-trailing .text-2 {
  width: fit-content;
}

.badge.icon-avatar .text-2 {
  background-image: url(../../../static/img/size-xs-placeholder-false-text-false-status-icon-false-state.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 16px;
  overflow: hidden;
  width: 16px;
}

.badge.icon-icon-leading .text-2 {
  width: fit-content;
}

.badge.icon-x-close .text-2 {
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.badge.icon-country .text-2 {
  width: fit-content;
}

.badge.icon-false .text-2 {
  width: fit-content;
}

.badge.icon-only .text-2 {
  width: fit-content;
}

.badge.icon-dot .text-2 {
  width: fit-content;
}

.badge.purple.icon-x-close .text-2 {
  color: var(--purple-700);
}

.badge.indigo.icon-x-close .text-2 {
  color: var(--indigo-700);
}

.badge.brand.icon-x-close .text-2 {
  color: var(--brand-700);
}

.badge.icon-x-close.gray-blue .text-2 {
  color: var(--gray-blue700);
}

.badge.success.icon-x-close .text-2 {
  color: var(--success-700);
}

.badge.blue-light.icon-x-close .text-2 {
  color: var(--blue-light700);
}

.badge.orange.icon-x-close .text-2 {
  color: var(--orange-700);
}

.badge.gray.icon-x-close .text-2 {
  color: var(--gray-700);
}

.badge.pill-outline.icon-x-close .text-2 {
  margin-top: -1.5px;
}

.badge.error.icon-x-close .text-2 {
  color: var(--error-700);
}

.badge.badge-modern.icon-x-close .text-2 {
  margin-top: -1px;
}

.badge.icon-x-close.warning .text-2 {
  color: var(--warning-700);
}

.badge.size-0-lg.icon-x-close .text-2 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.blue.icon-x-close .text-2 {
  color: var(--blue-700);
}

.badge.size-0-md.icon-x-close .text-2 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-color.icon-x-close .text-2 {
  margin-top: -1px;
}

.badge.size-0-sm.icon-x-close .text-2 {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.blue-gray.icon-x-close .text-2 {
  color: var(--gray-blue700);
}

.badge.pink.icon-x-close .text-2 {
  color: var(--pink-700);
}

.badge.pill-color.icon-x-close .text-2 {
  margin-top: -1px;
}

.badge.icon-avatar .badge-close-x {
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.badge.icon-x-close .badge-close-x {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  mix-blend-mode: darken;
  overflow: hidden;
  padding: 2px;
}

.badge.icon-avatar.gray .badge-close-x {
  color: var(--gray-700);
}

.badge.icon-avatar.orange .badge-close-x {
  color: var(--orange-700);
}

.badge.blue-gray.icon-avatar .badge-close-x {
  color: var(--gray-blue700);
}

.badge.icon-avatar.gray-blue .badge-close-x {
  color: var(--gray-blue700);
}

.badge.icon-avatar.purple .badge-close-x {
  color: var(--purple-700);
}

.badge.badge-color.icon-avatar .badge-close-x {
  margin-top: -1px;
}

.badge.icon-avatar.indigo .badge-close-x {
  color: var(--indigo-700);
}

.badge.icon-avatar.size-0-sm .badge-close-x {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.pill-outline.icon-x-close .badge-close-x {
  border-radius: 8px;
}

.badge.icon-avatar.badge-modern .badge-close-x {
  margin-top: -1px;
}

.badge.size-0-lg.icon-avatar .badge-close-x {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.icon-avatar.pill-color .badge-close-x {
  margin-top: -1px;
}

.badge.badge-modern.icon-x-close .badge-close-x {
  border-radius: 3px;
}

.badge.pill-outline.icon-avatar .badge-close-x {
  margin-top: -1.5px;
}

.badge.icon-avatar.size-0-md .badge-close-x {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.icon-avatar.blue .badge-close-x {
  color: var(--blue-700);
}

.badge.icon-avatar.warning .badge-close-x {
  color: var(--warning-700);
}

.badge.badge-color.icon-x-close .badge-close-x {
  border-radius: 3px;
}

.badge.icon-avatar.success .badge-close-x {
  color: var(--success-700);
}

.badge.icon-avatar.blue-light .badge-close-x {
  color: var(--blue-light700);
}

.badge.icon-avatar.pink .badge-close-x {
  color: var(--pink-700);
}

.badge.error.icon-avatar .badge-close-x {
  color: var(--error-700);
}

.badge.pill-color.icon-x-close .badge-close-x {
  border-radius: 8px;
}

.badge.icon-avatar.brand .badge-close-x {
  color: var(--brand-700);
}

.badge.gray .text-wrapper {
  color: var(--gray-700);
}

.badge.size-0-sm .text-wrapper {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.pill-color .text-wrapper {
  margin-top: -1px;
}

.badge.pill-outline .text-wrapper {
  margin-top: -1.5px;
}

.badge.size-0-md .text-wrapper {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-modern .text-wrapper {
  color: var(--gray-700);
  margin-top: -1px;
}

.badge.size-0-lg .text-wrapper {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-color .text-wrapper {
  margin-top: -1px;
}

.badge.badge-color.warning .text-wrapper {
  color: var(--warning-700);
}

.badge.pill-outline.blue-light .text-wrapper {
  color: var(--blue-light700);
}

.badge.badge-color.pink .text-wrapper {
  color: var(--pink-700);
}

.badge.blue.pill-color .text-wrapper {
  color: var(--blue-700);
}

.badge.pill-outline.error .text-wrapper {
  color: var(--error-700);
}

.badge.blue-light.pill-color .text-wrapper {
  color: var(--blue-light700);
}

.badge.pill-color.warning .text-wrapper {
  color: var(--warning-700);
}

.badge.badge-color.success .text-wrapper {
  color: var(--success-700);
}

.badge.pill-outline.warning .text-wrapper {
  color: var(--warning-700);
}

.badge.badge-color.blue-light .text-wrapper {
  color: var(--blue-light700);
}

.badge.pill-outline.pink .text-wrapper {
  color: var(--pink-700);
}

.badge.pill-outline.blue .text-wrapper {
  color: var(--blue-700);
}

.badge.badge-color.brand .text-wrapper {
  color: var(--brand-700);
}

.badge.pill-color.orange .text-wrapper {
  color: var(--orange-700);
}

.badge.pink.pill-color .text-wrapper {
  color: var(--pink-700);
}

.badge.badge-color.indigo .text-wrapper {
  color: var(--indigo-700);
}

.badge.pill-outline.blue-gray .text-wrapper {
  color: var(--gray-blue700);
}

.badge.pill-color.gray-blue .text-wrapper {
  color: var(--gray-blue700);
}

.badge.badge-color.blue .text-wrapper {
  color: var(--blue-700);
}

.badge.pill-outline.purple .text-wrapper {
  color: var(--purple-700);
}

.badge.badge-color.error .text-wrapper {
  color: var(--error-700);
}

.badge.success.pill-color .text-wrapper {
  color: var(--success-700);
}

.badge.badge-color.blue-gray .text-wrapper {
  color: var(--gray-blue700);
}

.badge.indigo.pill-color .text-wrapper {
  color: var(--indigo-700);
}

.badge.error.pill-color .text-wrapper {
  color: var(--error-700);
}

.badge.badge-color.orange .text-wrapper {
  color: var(--orange-700);
}

.badge.brand.pill-color .text-wrapper {
  color: var(--brand-700);
}

.badge.purple.pill-color .text-wrapper {
  color: var(--purple-700);
}

.badge.badge-color.purple .text-wrapper {
  color: var(--purple-700);
}

.badge.pill-outline.indigo .text-wrapper {
  color: var(--indigo-700);
}

.badge.pill-outline.gray-blue .text-wrapper {
  color: var(--gray-blue700);
}

.badge.pill-outline.orange .text-wrapper {
  color: var(--orange-700);
}

.badge.pill-outline.brand .text-wrapper {
  color: var(--brand-700);
}

.badge.badge-color.gray-blue .text-wrapper {
  color: var(--gray-blue700);
}

.badge.pill-outline.success .text-wrapper {
  color: var(--success-700);
}

.badge.gray .text-3 {
  color: var(--gray-700);
}

.badge.size-0-sm .text-3 {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.warning .text-3 {
  color: var(--warning-700);
}

.badge.pill-color .text-3 {
  margin-top: -1px;
}

.badge.purple .text-3 {
  color: var(--purple-700);
}

.badge.blue .text-3 {
  color: var(--blue-700);
}

.badge.brand .text-3 {
  color: var(--brand-700);
}

.badge.pill-outline .text-3 {
  margin-top: -1.5px;
}

.badge.error .text-3 {
  color: var(--error-700);
}

.badge.blue-light .text-3 {
  color: var(--blue-light700);
}

.badge.indigo .text-3 {
  color: var(--indigo-700);
}

.badge.gray-blue .text-3 {
  color: var(--gray-blue700);
}

.badge.size-0-md .text-3 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-modern .text-3 {
  margin-top: -1px;
}

.badge.size-0-lg .text-3 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.pink .text-3 {
  color: var(--pink-700);
}

.badge.badge-color .text-3 {
  margin-top: -1px;
}

.badge.orange .text-3 {
  color: var(--orange-700);
}

.badge.success .text-3 {
  color: var(--success-700);
}

.badge.blue-gray .text-3 {
  color: var(--gray-blue700);
}
