.login {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Take the full viewport height */
  width: 100%;   /* Take the full viewport width */
  overflow: hidden; /* Prevent scrolling */
}

.login .content-wrapper {
  background-color: #fff;
  display: flex;   /* Added to allow centering of child */
  justify-content: center; /* Center child horizontally */
  align-items: center;     /* Center child vertically */
  height: 100%;    /* Take the full height of parent */
  width: 100%;     /* Take the full width of parent */
  box-sizing: border-box;
}

.login .content-5 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  max-width: 360px;
  width: 100%;
}

.login .header {
  align-items: center;
  align-self: stretch;
  background-color: transparent;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;
  width: 100%;
}

.login .logo {
  height: 24px;
  position: relative;
  width: 116px;
}

.login .vector {
  height: 19px;
  left: 36px;
  position: absolute;
  top: 3px;
  width: 78px;
}

.login .logomark-instance {
  height: 17px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 25px !important;
}

.login .text-and-supporting {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.login .p {
  align-self: stretch;
  color: var(--gray-900);
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.login .supporting-text-2 {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  position: relative;
  text-align: center;
}

.login .content-6 {
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
  width: 100%;
}

.login .form {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 9px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.login .input-field-instance {
  align-self: stretch !important;
  background-color: #f4f4f5;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.login .design-component-instance-node {
  border-radius: 16px !important;
  box-shadow: unset !important;
}

/* Resetting the styles of .input-field-2 to make it look like .input-field-instance */
.login .input-field-2 {
  height: 62px;
  background-color: #f4f4f5;
  border: 1px solid #f4f4f5 !important; /* inherit from .input-field-instance */
  border-radius: 16px !important; /* inherit from .input-field-instance */
  padding: 10px 15px !important; /* inherit from .input-field-instance */
  margin-bottom: 10px !important; /* inherit from .input-field-instance */
  font-size: 16px !important; /* inherit from .input-field-instance */
  transition: border-color 0.3s !important; /* inherit from .input-field-instance */
  box-shadow: unset !important;
  width: 100% !important;
  align-self: stretch !important;
  flex: 0 0 auto !important;
}

.login .input-field-2:focus {
  border-color: #007BFF !important; /* inherit from .input-field-instance:focus */
  outline: none !important;
}


.login .actions {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.login .buttons-button-instance {
  align-self: stretch !important;
  font-weight: 500;
  background-color: #121315 !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
  border: none !important; /* Remove default border */
  box-shadow: none !important; /* Remove default box-shadow */
  outline: none !important; /* Remove focus outline */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}


.login .footer-links {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 52px;
  position: relative;
  width: 100%;
}

.login .row {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.login .text-14 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.login .buttons-button-2 {
  color: #121315 !important;
}

.login .buttons-button-3 {
  flex: 0 0 auto !important;
}
/* Input Fields */
.login .input-field-instance {
  height: 62px;
  padding: 10px 15px;
  margin-bottom: 16px;
  border: 1px solid #f4f4f5;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.login .input-field-instance:focus {
  border-color: #007BFF; /* You can change this to your primary color. */
  outline: none;
}

/* Button */
.login .buttons-button-instance {
  padding: 10px 15px;
  height: 58px;
  background-color: #007BFF; /* Adjust the color to your preference. */
  color: white;
  border-radius: 14px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.login .buttons-button-instance:hover {
  background-color: #0056b3; /* A slightly darker shade for hover. */
}
.error-message {
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 14px;
  height: 38px;
  color: #CA2240;
  border-radius: 16px !important; /* inherit from .input-field-instance */
  padding: 10px 15px !important; /* inherit from .input-field-instance */
  margin-bottom: 10px !important; /* inherit from .input-field-instance */
  margin: 10px 0;
  text-align: center;
  align-self: stretch !important;
  background-color: #fef3f2;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.success-message {
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 14px;
  color: #1EC17E;
  border-radius: 16px; /* inherit from .input-field-instance */
  padding: 10px 15px; /* Adjust padding as needed */
  margin: 10px 0;
  text-align: center;
  background-color: #EAFFF6;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px; /* Adjust height as needed, or consider removing if not needed */
  width: 100%;
}