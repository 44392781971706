.table-header {
  align-items: center;
  display: inline-flex;
  gap: 4px;
  position: relative;
}

.table-header .text-4 {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.table-header .help-icon {
  background-size: 100% 100%;
  height: 16px;
  position: relative;
  width: 16px;
}

.table-header .instance-node-2 {
  height: 16px !important;
  position: relative !important;
  width: 16px !important;
}

.table-header .disabled {
  color: var(--gray-500);
}

.table-header .default {
  color: var(--gray-600);
}

.table-header .hover {
  color: var(--gray-700);
}

.table-header .up.state-hover {
  background-image: url(../../../static/img/help-circle-8.svg);
}

.table-header .down.state-default {
  background-image: url(../../../static/img/help-circle-3.svg);
}

.table-header .down.state-disabled {
  background-image: url(../../../static/img/help-circle-5.svg);
}

.table-header .state-default.false {
  background-image: url(../../../static/img/help-circle.svg);
}

.table-header .up.state-disabled {
  background-image: url(../../../static/img/help-circle-6.svg);
}

.table-header .up.state-default {
  background-image: url(../../../static/img/help-circle-4.svg);
}

.table-header .false.state-disabled {
  background-image: url(../../../static/img/help-circle-1.svg);
}

.table-header .state-hover.false {
  background-image: url(../../../static/img/help-circle-2.svg);
}

.table-header .state-hover.down {
  background-image: url(../../../static/img/help-circle-7.svg);
}

.transaction-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* This will ensure that each column is spaced evenly */
  width: 100%;
  border-bottom: 1px solid #e0e0e0;
}

.loading {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: loading 1.2s ease-in-out infinite;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinning {
  animation: spin 1s linear;
}

.active {
  background-color: var(--gray-700);
  color: white;
}
.symbol-circle {
  width: 40px;
  height: 40px;
  border-color: #07f3;
  border-width: 1px;
  border-style: solid;
  border-radius: 50%;
  background-color: #0077ff1a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0177ff;
  font-family: 'Inter', sans-serif; /* This line sets the Inter font */
}
.chart-container {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-sm);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1216px;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 200px;
}
.chart-container h3 {
  font-size: 1.125rem;
  color: #333; /* Adjust color as per your design */
  margin-bottom: 16px;
  border-bottom: 1px solid #E1E4E8;
  padding-bottom: 8px;
}