:root {
  --baseblack: #000;
  --basewhite: #fff;
  --blue-200: #b2ddff;
  --blue-50: #eff8ff;
  --blue-500: #2e90fa;
  --blue-600: #1570ef;
  --blue-700: #175cd3;
  --blue-light200: #b9e6fe;
  --blue-light50: #f0f9ff;
  --blue-light500: #0ba5ec;
  --blue-light600: #0086c9;
  --blue-light700: #026aa2;
  --brand-200: #e9d7fe;
  --brand-50: #f9f5ff;
  --brand-500: #9e77ed;
  --brand-600: #7f56d9;
  --brand-700: #6941c6;
  --display-md-semibold-font-family: "Inter-SemiBold", Helvetica;
  --display-md-semibold-font-size: 36px;
  --display-md-semibold-font-style: normal;
  --display-md-semibold-font-weight: 600;
  --display-md-semibold-letter-spacing: -.72px;
  --display-md-semibold-line-height: 44px;
  --display-sm-semibold-font-family: "Inter-SemiBold", Helvetica;
  --display-sm-semibold-font-size: 30px;
  --display-sm-semibold-font-style: normal;
  --display-sm-semibold-font-weight: 600;
  --display-sm-semibold-letter-spacing: 0px;
  --display-sm-semibold-line-height: 38px;
  --error-200: #fecdca;
  --error-50: #fef3f2;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #b42318;
  --gray-100: #f2f4f7;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-50: #f9fafb;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #182230;
  --gray-900: #101828;
  --gray-blue200: #d5d9eb;
  --gray-blue50: #f8f9fc;
  --gray-blue500: #4e5ba6;
  --gray-blue600: #3e4784;
  --gray-blue700: #363f72;
  --indigo-200: #c7d7fe;
  --indigo-50: #eef4ff;
  --indigo-500: #6172f3;
  --indigo-600: #444ce7;
  --indigo-700: #3538cd;
  --orange-200: #f9dbaf;
  --orange-50: #fef6ee;
  --orange-500: #ef6820;
  --orange-600: #e04f16;
  --orange-700: #b93815;
  --pink-200: #fcceee;
  --pink-50: #fdf2fa;
  --pink-500: #ee46bc;
  --pink-600: #dd2590;
  --pink-700: #c11574;
  --purple-200: #d9d6fe;
  --purple-50: #f4f3ff;
  --purple-500: #7a5af8;
  --purple-600: #6938ef;
  --purple-700: #5925dc;
  --shadows-shadow-sm: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  --shadows-shadow-xl: 0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814;
  --shadows-shadow-xs: 0px 1px 2px 0px #1018280d;
  --success-100: #dcfae6;
  --success-200: #abefc6;
  --success-50: #ecfdf3;
  --success-500: #17b26a;
  --success-600: #079455;
  --success-700: #067647;
  --text-lg-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-lg-semibold-font-size: 18px;
  --text-lg-semibold-font-style: normal;
  --text-lg-semibold-font-weight: 600;
  --text-lg-semibold-letter-spacing: 0px;
  --text-lg-semibold-line-height: 28px;
  --text-md-regular-font-family: "Inter-Regular", Helvetica;
  --text-md-regular-font-size: 16px;
  --text-md-regular-font-style: normal;
  --text-md-regular-font-weight: 400;
  --text-md-regular-letter-spacing: 0px;
  --text-md-regular-line-height: 24px;
  --text-md-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-md-semibold-font-size: 16px;
  --text-md-semibold-font-style: normal;
  --text-md-semibold-font-weight: 600;
  --text-md-semibold-letter-spacing: 0px;
  --text-md-semibold-line-height: 24px;
  --text-sm-medium-font-family: "Inter-Medium", Helvetica;
  --text-sm-medium-font-size: 14px;
  --text-sm-medium-font-style: normal;
  --text-sm-medium-font-weight: 500;
  --text-sm-medium-letter-spacing: 0px;
  --text-sm-medium-line-height: 20px;
  --text-sm-regular-font-family: "Inter-Regular", Helvetica;
  --text-sm-regular-font-size: 14px;
  --text-sm-regular-font-style: normal;
  --text-sm-regular-font-weight: 400;
  --text-sm-regular-letter-spacing: 0px;
  --text-sm-regular-line-height: 20px;
  --text-sm-semibold-font-family: "Inter-SemiBold", Helvetica;
  --text-sm-semibold-font-size: 14px;
  --text-sm-semibold-font-style: normal;
  --text-sm-semibold-font-weight: 600;
  --text-sm-semibold-letter-spacing: 0px;
  --text-sm-semibold-line-height: 20px;
  --text-xs-medium-font-family: "Inter-Medium", Helvetica;
  --text-xs-medium-font-size: 12px;
  --text-xs-medium-font-style: normal;
  --text-xs-medium-font-weight: 500;
  --text-xs-medium-letter-spacing: 0px;
  --text-xs-medium-line-height: 18px;
  --warning-200: #fedf89;
  --warning-50: #fffaeb;
  --warning-500: #f79009;
  --warning-600: #dc6803;
  --warning-700: #b54708;
}

.dot .div {
  background-color: var(--success-500);
  position: relative;
}

.dot.md {
  width: 10px;
  height: 10px;
}

.dot.lg {
  width: 12px;
  height: 12px;
}

.dot.sm {
  width: 8px;
  height: 8px;
}

.dot .outline-false {
  top: 1px;
  left: 1px;
}

.dot .outline-true {
  border-color: var(--success-100);
}

.dot .size-lg.outline-true {
  border: 5px solid;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: -4px;
  left: -4px;
}

.dot .size-lg.outline-false {
  border-radius: 5px;
  width: 10px;
  height: 10px;
}

.dot .outline-true.size-md {
  border: 4px solid;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  top: -3px;
  left: -3px;
}

.dot .outline-false.size-md {
  border-radius: 4px;
  width: 8px;
  height: 8px;
}

.dot .outline-true.size-sm {
  border: 3px solid;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  top: -2px;
  left: -2px;
}

.dot .outline-false.size-sm {
  border-radius: 3px;
  width: 6px;
  height: 6px;
}

.badge {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.badge .text {
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.badge .class {
  background-color: var(--brand-500) !important;
}

.badge .class-2 {
  background-color: var(--brand-600) !important;
}

.badge .class-3 {
  background-color: var(--warning-500) !important;
}

.badge .class-4 {
  background-color: var(--warning-600) !important;
}

.badge .class-5 {
  background-color: var(--success-600) !important;
}

.badge .class-6 {
  background-color: var(--gray-blue500) !important;
}

.badge .class-7 {
  background-color: var(--blue-light500) !important;
}

.badge .class-8 {
  background-color: var(--indigo-500) !important;
}

.badge .class-9 {
  background-color: var(--purple-500) !important;
}

.badge .class-10 {
  background-color: var(--blue-500) !important;
}

.badge .class-11 {
  background-color: var(--pink-500) !important;
}

.badge .class-12 {
  background-color: var(--orange-500) !important;
}

.badge .class-13 {
  background-color: var(--error-500) !important;
}

.badge .class-14 {
  background-color: var(--error-600) !important;
}

.badge .class-15 {
  background-color: var(--gray-500) !important;
}

.badge .class-16 {
  background-color: var(--gray-600) !important;
}

.badge .dot-instance {
  position: relative !important;
}

.badge .text-2 {
  position: relative;
}

.badge .contrast-border {
  border: .33px solid;
  border-color: var(--baseblack);
  opacity: .08;
  border-radius: 133.33px;
  height: 16px;
}

.badge .badge-close-x {
  position: relative;
}

.badge .instance-node {
  width: 12px !important;
  height: 12px !important;
  position: relative !important;
}

.badge .text-wrapper {
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.badge .AU {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.badge .text-3 {
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.badge.icon-icon-trailing, .badge.icon-icon-leading {
  gap: 4px;
}

.badge.pill-color {
  border: 1px solid;
  border-radius: 16px;
}

.badge.icon-x-close {
  gap: 2px;
}

.badge.pill-outline {
  border: 1.5px solid;
  border-radius: 16px;
}

.badge.gray-blue {
  background-color: var(--gray-blue50);
  border-color: var(--gray-blue200);
}

.badge.badge-modern {
  box-shadow: var(--shadows-shadow-xs);
  border: 1px solid;
}

.badge.badge-color {
  border: 1px solid;
}

.badge.icon-only {
  gap: 4px;
}

.badge.badge-color.warning {
  background-color: var(--warning-50);
  border-color: var(--warning-200);
}

.badge.size-0-lg.badge-color {
  border-radius: 8px;
}

.badge.pill-outline.blue-light {
  border-color: var(--blue-light600);
}

.badge.badge-color.pink {
  background-color: var(--pink-50);
  border-color: var(--pink-200);
}

.badge.pill-outline.gray {
  border-color: var(--gray-600);
}

.badge.blue.pill-color {
  background-color: var(--blue-50);
  border-color: var(--blue-200);
}

.badge.size-0-lg.icon-country {
  gap: 6px;
}

.badge.pill-outline.error {
  border-color: var(--error-600);
}

.badge.badge-modern.warning {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.blue-light.pill-color {
  background-color: var(--blue-light50);
  border-color: var(--blue-light200);
}

.badge.pill-color.warning {
  background-color: var(--warning-50);
  border-color: var(--warning-200);
}

.badge.blue-light.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.success {
  background-color: var(--success-50);
  border-color: var(--success-200);
}

.badge.pill-outline.warning {
  border-color: var(--warning-600);
}

.badge.badge-color.blue-light {
  background-color: var(--blue-light50);
  border-color: var(--blue-light200);
}

.badge.pill-outline.pink {
  border-color: var(--pink-600);
}

.badge.pill-outline.blue {
  border-color: var(--blue-600);
}

.badge.badge-color.brand {
  background-color: var(--brand-50);
  border-color: var(--brand-200);
}

.badge.pill-color.orange {
  background-color: var(--orange-50);
  border-color: var(--orange-200);
}

.badge.success.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.pink.pill-color {
  background-color: var(--pink-50);
  border-color: var(--pink-200);
}

.badge.badge-color.indigo {
  background-color: var(--indigo-50);
  border-color: var(--indigo-200);
}

.badge.badge-color.size-0-sm {
  border-radius: 6px;
}

.badge.size-0-md.icon-country {
  gap: 6px;
}

.badge.pill-outline.blue-gray {
  border-color: var(--gray-blue600);
}

.badge.size-0-md.icon-only {
  padding: 6px;
}

.badge.size-0-sm.badge-modern {
  border-radius: 6px;
}

.badge.indigo.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.blue {
  background-color: var(--blue-50);
  border-color: var(--blue-200);
}

.badge.size-0-sm.icon-country {
  gap: 4px;
}

.badge.gray.pill-color {
  background-color: var(--gray-50);
  border-color: var(--gray-200);
}

.badge.badge-modern.orange {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.pill-outline.purple {
  border-color: var(--purple-600);
}

.badge.size-0-lg.icon-only {
  padding: 8px;
}

.badge.gray.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.icon-avatar.size-0-sm {
  gap: 4px;
}

.badge.blue-gray.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.error {
  background-color: var(--error-50);
  border-color: var(--error-200);
}

.badge.success.pill-color {
  background-color: var(--success-50);
  border-color: var(--success-200);
}

.badge.error.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.blue-gray {
  background-color: var(--gray-blue50);
  border-color: var(--gray-blue200);
}

.badge.indigo.pill-color {
  background-color: var(--indigo-50);
  border-color: var(--indigo-200);
}

.badge.error.pill-color {
  background-color: var(--error-50);
  border-color: var(--error-200);
}

.badge.badge-color.orange {
  background-color: var(--orange-50);
  border-color: var(--orange-200);
}

.badge.size-0-md.badge-modern {
  border-radius: 6px;
}

.badge.brand.pill-color {
  background-color: var(--brand-50);
  border-color: var(--brand-200);
}

.badge.size-0-lg.icon-avatar {
  gap: 6px;
}

.badge.badge-color.purple, .badge.purple.pill-color {
  background-color: var(--purple-50);
  border-color: var(--purple-200);
}

.badge.icon-dot.size-0-md {
  gap: 6px;
}

.badge.blue.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.icon-avatar.size-0-md {
  gap: 6px;
}

.badge.pink.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.size-0-lg.badge-modern {
  border-radius: 8px;
}

.badge.pill-outline.indigo {
  border-color: var(--indigo-600);
}

.badge.pill-outline.success {
  border-color: var(--success-600);
}

.badge.size-0-lg.icon-dot {
  gap: 6px;
}

.badge.brand.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.size-0-sm.icon-only {
  padding: 5px;
}

.badge.pill-outline.orange {
  border-color: var(--orange-600);
}

.badge.purple.badge-modern {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
}

.badge.badge-color.gray {
  background-color: var(--gray-50);
  border-color: var(--gray-200);
}

.badge.pill-outline.brand {
  border-color: var(--brand-600);
}

.badge.badge-color.size-0-md {
  border-radius: 6px;
}

.badge.size-0-sm.icon-dot {
  gap: 4px;
}

.badge.size-0-lg.icon-dot.pill-color {
  padding: 4px 12px 4px 10px;
}

.badge.pill-outline.size-0-sm.icon-dot {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.badge-color.icon-icon-trailing {
  padding: 4px 8px 4px 10px;
}

.badge.badge-color.icon-avatar.size-0-sm {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-sm.badge-modern.icon-x-close {
  padding: 2px 3px 2px 6px;
}

.badge.size-0-sm.icon-dot.badge-modern {
  padding: 2px 6px;
}

.badge.size-0-sm.icon-country.pill-color {
  padding: 2px 8px 2px 3px;
}

.badge.badge-color.size-0-md.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.size-0-md.pill-color.icon-x-close {
  padding: 2px 4px 2px 10px;
}

.badge.size-0-lg.badge-color.icon-country {
  padding: 4px 10px 4px 8px;
}

.badge.size-0-md.icon-country.badge-modern, .badge.badge-color.icon-avatar.size-0-md {
  padding: 2px 8px 2px 6px;
}

.badge.badge-color.size-0-sm.icon-icon-trailing {
  padding: 2px 4px 2px 6px;
}

.badge.size-0-lg.icon-avatar.pill-outline {
  padding: 4px 12px 4px 6px;
}

.badge.size-0-lg.badge-color.icon-dot {
  padding: 4px 10px;
}

.badge.size-0-md.icon-country.pill-color {
  padding: 2px 10px 2px 4px;
}

.badge.size-0-lg.icon-false.badge-modern {
  padding: 4px 10px;
}

.badge.size-0-lg.pill-outline.icon-icon-trailing {
  padding: 4px 10px 4px 12px;
}

.badge.size-0-sm.pill-color.icon-x-close {
  padding: 2px 3px 2px 8px;
}

.badge.pill-outline.icon-avatar.size-0-sm {
  padding: 2px 8px 2px 3px;
}

.badge.pill-outline.size-0-sm.icon-x-close {
  padding: 2px 3px 2px 8px;
}

.badge.size-0-lg.pill-color.icon-icon-trailing {
  padding: 4px 10px 4px 12px;
}

.badge.size-0-lg.badge-color.icon-false {
  padding: 4px 10px;
}

.badge.size-0-sm.icon-icon-leading.pill-color {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.pill-outline.icon-x-close {
  padding: 4px 6px 4px 12px;
}

.badge.size-0-md.icon-false.badge-modern {
  padding: 2px 8px;
}

.badge.badge-color.size-0-sm.icon-country {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-lg.icon-dot.pill-outline {
  padding: 4px 12px 4px 10px;
}

.badge.size-0-lg.icon-country.pill-outline {
  padding: 4px 12px 4px 6px;
}

.badge.size-0-lg.icon-country.badge-modern {
  padding: 4px 10px 4px 8px;
}

.badge.icon-avatar.size-0-md.pill-color {
  padding: 2px 10px 2px 4px;
}

.badge.pill-outline.size-0-sm.icon-icon-leading {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.pill-color.icon-x-close {
  padding: 4px 6px 4px 12px;
}

.badge.badge-color.size-0-sm.icon-dot {
  padding: 2px 6px;
}

.badge.badge-color.size-0-sm.icon-x-close {
  padding: 2px 3px 2px 6px;
}

.badge.size-0-lg.badge-color.icon-icon-leading {
  padding: 4px 10px 4px 8px;
}

.badge.size-0-lg.icon-false.pill-outline {
  padding: 4px 12px;
}

.badge.pill-outline.size-0-md.icon-icon-leading {
  padding: 2px 10px 2px 8px;
}

.badge.size-0-lg.icon-avatar.badge-color {
  padding: 4px 10px 4px 8px;
}

.badge.pill-outline.size-0-md.icon-x-close {
  padding: 2px 4px 2px 10px;
}

.badge.size-0-md.pill-color.icon-icon-trailing {
  padding: 2px 8px 2px 10px;
}

.badge.size-0-lg.icon-country.pill-color {
  padding: 4px 12px 4px 6px;
}

.badge.pill-outline.icon-avatar.size-0-md {
  padding: 2px 10px 2px 4px;
}

.badge.pill-outline.size-0-md.icon-false {
  padding: 2px 10px;
}

.badge.badge-color.size-0-md.icon-icon-leading, .badge.size-0-sm.icon-dot.pill-color {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-lg.icon-icon-leading.badge-modern {
  padding: 4px 10px 4px 8px;
}

.badge.size-0-lg.badge-modern.icon-icon-trailing {
  padding: 4px 8px 4px 10px;
}

.badge.size-0-lg.icon-icon-leading.pill-outline {
  padding: 4px 12px 4px 10px;
}

.badge.badge-color.size-0-sm.icon-icon-leading {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-lg.icon-icon-leading.pill-color {
  padding: 4px 12px 4px 10px;
}

.badge.pill-outline.size-0-sm.icon-false {
  padding: 2px 8px;
}

.badge.size-0-lg.icon-avatar.pill-color {
  padding: 4px 12px 4px 6px;
}

.badge.size-0-sm.icon-country.badge-modern {
  padding: 2px 6px 2px 4px;
}

.badge.pill-outline.size-0-md.icon-country {
  padding: 2px 10px 2px 4px;
}

.badge.pill-outline.icon-dot.size-0-md {
  padding: 2px 10px 2px 8px;
}

.badge.icon-dot.size-0-md.badge-modern {
  padding: 2px 8px;
}

.badge.icon-dot.size-0-md.pill-color {
  padding: 2px 10px 2px 8px;
}

.badge.size-0-md.badge-modern.icon-x-close {
  padding: 2px 4px 2px 8px;
}

.badge.size-0-lg.icon-dot.badge-modern {
  padding: 4px 10px;
}

.badge.size-0-sm.pill-color.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.size-0-md.icon-icon-leading.badge-modern {
  padding: 2px 8px 2px 6px;
}

.badge.pill-outline.size-0-md.icon-icon-trailing {
  padding: 2px 8px 2px 10px;
}

.badge.icon-avatar.size-0-sm.pill-color {
  padding: 2px 8px 2px 3px;
}

.badge.icon-avatar.size-0-sm.badge-modern {
  padding: 2px 6px 2px 4px;
}

.badge.size-0-lg.icon-false.pill-color {
  padding: 4px 12px;
}

.badge.badge-modern.size-0-md.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.badge-color.size-0-md.icon-false {
  padding: 2px 8px;
}

.badge.size-0-lg.icon-avatar.badge-modern {
  padding: 4px 10px 4px 8px;
}

.badge.pill-outline.size-0-sm.icon-icon-trailing {
  padding: 2px 6px 2px 8px;
}

.badge.size-0-md.icon-false.pill-color {
  padding: 2px 10px;
}

.badge.icon-avatar.size-0-md.badge-modern {
  padding: 2px 8px 2px 6px;
}

.badge.badge-color.size-0-md.icon-x-close {
  padding: 2px 4px 2px 8px;
}

.badge.size-0-sm.icon-icon-leading.badge-modern {
  padding: 2px 6px 2px 4px;
}

.badge.badge-color.icon-dot.size-0-md, .badge.size-0-sm.icon-false.pill-color {
  padding: 2px 8px;
}

.badge.badge-color.size-0-sm.icon-false {
  padding: 2px 6px;
}

.badge.badge-modern.size-0-sm.icon-icon-trailing {
  padding: 2px 4px 2px 6px;
}

.badge.size-0-lg.badge-color.icon-x-close {
  padding: 4px 6px 4px 10px;
}

.badge.size-0-md.icon-icon-leading.pill-color {
  padding: 2px 10px 2px 8px;
}

.badge.size-0-lg.badge-modern.icon-x-close {
  padding: 4px 6px 4px 10px;
}

.badge.pill-outline.size-0-sm.icon-country {
  padding: 2px 8px 2px 3px;
}

.badge.badge-color.size-0-md.icon-country {
  padding: 2px 8px 2px 6px;
}

.badge.size-0-sm.icon-false.badge-modern {
  padding: 2px 6px;
}

.badge.gray .text {
  color: var(--gray-700);
}

.badge.size-0-sm .text {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.warning .text {
  color: var(--warning-700);
}

.badge.pill-color .text {
  margin-top: -1px;
}

.badge.purple .text {
  color: var(--purple-700);
}

.badge.blue .text {
  color: var(--blue-700);
}

.badge.brand .text {
  color: var(--brand-700);
}

.badge.pill-outline .text {
  margin-top: -1.5px;
}

.badge.error .text {
  color: var(--error-700);
}

.badge.blue-light .text {
  color: var(--blue-light700);
}

.badge.indigo .text {
  color: var(--indigo-700);
}

.badge.gray-blue .text {
  color: var(--gray-blue700);
}

.badge.size-0-md .text {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-modern .text {
  margin-top: -1px;
}

.badge.size-0-lg .text {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.pink .text {
  color: var(--pink-700);
}

.badge.badge-color .text {
  margin-top: -1px;
}

.badge.orange .text {
  color: var(--orange-700);
}

.badge.success .text {
  color: var(--success-700);
}

.badge.blue-gray .text {
  color: var(--gray-blue700);
}

.badge.icon-icon-trailing .text-2 {
  width: -moz-fit-content;
  width: fit-content;
}

.badge.icon-avatar .text-2 {
  background-image: url("size-xs-placeholder-false-text-false-status-icon-false-state.8b99397d.png");
  background-position: 50%;
  background-size: cover;
  border-radius: 8px;
  width: 16px;
  height: 16px;
  overflow: hidden;
}

.badge.icon-icon-leading .text-2 {
  width: -moz-fit-content;
  width: fit-content;
}

.badge.icon-x-close .text-2 {
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.badge.icon-country .text-2, .badge.icon-false .text-2, .badge.icon-only .text-2, .badge.icon-dot .text-2 {
  width: -moz-fit-content;
  width: fit-content;
}

.badge.purple.icon-x-close .text-2 {
  color: var(--purple-700);
}

.badge.indigo.icon-x-close .text-2 {
  color: var(--indigo-700);
}

.badge.brand.icon-x-close .text-2 {
  color: var(--brand-700);
}

.badge.icon-x-close.gray-blue .text-2 {
  color: var(--gray-blue700);
}

.badge.success.icon-x-close .text-2 {
  color: var(--success-700);
}

.badge.blue-light.icon-x-close .text-2 {
  color: var(--blue-light700);
}

.badge.orange.icon-x-close .text-2 {
  color: var(--orange-700);
}

.badge.gray.icon-x-close .text-2 {
  color: var(--gray-700);
}

.badge.pill-outline.icon-x-close .text-2 {
  margin-top: -1.5px;
}

.badge.error.icon-x-close .text-2 {
  color: var(--error-700);
}

.badge.badge-modern.icon-x-close .text-2 {
  margin-top: -1px;
}

.badge.icon-x-close.warning .text-2 {
  color: var(--warning-700);
}

.badge.size-0-lg.icon-x-close .text-2 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.blue.icon-x-close .text-2 {
  color: var(--blue-700);
}

.badge.size-0-md.icon-x-close .text-2 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-color.icon-x-close .text-2 {
  margin-top: -1px;
}

.badge.size-0-sm.icon-x-close .text-2 {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.blue-gray.icon-x-close .text-2 {
  color: var(--gray-blue700);
}

.badge.pink.icon-x-close .text-2 {
  color: var(--pink-700);
}

.badge.pill-color.icon-x-close .text-2 {
  margin-top: -1px;
}

.badge.icon-avatar .badge-close-x {
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
}

.badge.icon-x-close .badge-close-x {
  mix-blend-mode: darken;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  padding: 2px;
  display: inline-flex;
  overflow: hidden;
}

.badge.icon-avatar.gray .badge-close-x {
  color: var(--gray-700);
}

.badge.icon-avatar.orange .badge-close-x {
  color: var(--orange-700);
}

.badge.blue-gray.icon-avatar .badge-close-x, .badge.icon-avatar.gray-blue .badge-close-x {
  color: var(--gray-blue700);
}

.badge.icon-avatar.purple .badge-close-x {
  color: var(--purple-700);
}

.badge.badge-color.icon-avatar .badge-close-x {
  margin-top: -1px;
}

.badge.icon-avatar.indigo .badge-close-x {
  color: var(--indigo-700);
}

.badge.icon-avatar.size-0-sm .badge-close-x {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.pill-outline.icon-x-close .badge-close-x {
  border-radius: 8px;
}

.badge.icon-avatar.badge-modern .badge-close-x {
  margin-top: -1px;
}

.badge.size-0-lg.icon-avatar .badge-close-x {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.icon-avatar.pill-color .badge-close-x {
  margin-top: -1px;
}

.badge.badge-modern.icon-x-close .badge-close-x {
  border-radius: 3px;
}

.badge.pill-outline.icon-avatar .badge-close-x {
  margin-top: -1.5px;
}

.badge.icon-avatar.size-0-md .badge-close-x {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.icon-avatar.blue .badge-close-x {
  color: var(--blue-700);
}

.badge.icon-avatar.warning .badge-close-x {
  color: var(--warning-700);
}

.badge.badge-color.icon-x-close .badge-close-x {
  border-radius: 3px;
}

.badge.icon-avatar.success .badge-close-x {
  color: var(--success-700);
}

.badge.icon-avatar.blue-light .badge-close-x {
  color: var(--blue-light700);
}

.badge.icon-avatar.pink .badge-close-x {
  color: var(--pink-700);
}

.badge.error.icon-avatar .badge-close-x {
  color: var(--error-700);
}

.badge.pill-color.icon-x-close .badge-close-x {
  border-radius: 8px;
}

.badge.icon-avatar.brand .badge-close-x {
  color: var(--brand-700);
}

.badge.gray .text-wrapper {
  color: var(--gray-700);
}

.badge.size-0-sm .text-wrapper {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.pill-color .text-wrapper {
  margin-top: -1px;
}

.badge.pill-outline .text-wrapper {
  margin-top: -1.5px;
}

.badge.size-0-md .text-wrapper {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-modern .text-wrapper {
  color: var(--gray-700);
  margin-top: -1px;
}

.badge.size-0-lg .text-wrapper {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-color .text-wrapper {
  margin-top: -1px;
}

.badge.badge-color.warning .text-wrapper {
  color: var(--warning-700);
}

.badge.pill-outline.blue-light .text-wrapper {
  color: var(--blue-light700);
}

.badge.badge-color.pink .text-wrapper {
  color: var(--pink-700);
}

.badge.blue.pill-color .text-wrapper {
  color: var(--blue-700);
}

.badge.pill-outline.error .text-wrapper {
  color: var(--error-700);
}

.badge.blue-light.pill-color .text-wrapper {
  color: var(--blue-light700);
}

.badge.pill-color.warning .text-wrapper {
  color: var(--warning-700);
}

.badge.badge-color.success .text-wrapper {
  color: var(--success-700);
}

.badge.pill-outline.warning .text-wrapper {
  color: var(--warning-700);
}

.badge.badge-color.blue-light .text-wrapper {
  color: var(--blue-light700);
}

.badge.pill-outline.pink .text-wrapper {
  color: var(--pink-700);
}

.badge.pill-outline.blue .text-wrapper {
  color: var(--blue-700);
}

.badge.badge-color.brand .text-wrapper {
  color: var(--brand-700);
}

.badge.pill-color.orange .text-wrapper {
  color: var(--orange-700);
}

.badge.pink.pill-color .text-wrapper {
  color: var(--pink-700);
}

.badge.badge-color.indigo .text-wrapper {
  color: var(--indigo-700);
}

.badge.pill-outline.blue-gray .text-wrapper, .badge.pill-color.gray-blue .text-wrapper {
  color: var(--gray-blue700);
}

.badge.badge-color.blue .text-wrapper {
  color: var(--blue-700);
}

.badge.pill-outline.purple .text-wrapper {
  color: var(--purple-700);
}

.badge.badge-color.error .text-wrapper {
  color: var(--error-700);
}

.badge.success.pill-color .text-wrapper {
  color: var(--success-700);
}

.badge.badge-color.blue-gray .text-wrapper {
  color: var(--gray-blue700);
}

.badge.indigo.pill-color .text-wrapper {
  color: var(--indigo-700);
}

.badge.error.pill-color .text-wrapper {
  color: var(--error-700);
}

.badge.badge-color.orange .text-wrapper {
  color: var(--orange-700);
}

.badge.brand.pill-color .text-wrapper {
  color: var(--brand-700);
}

.badge.purple.pill-color .text-wrapper, .badge.badge-color.purple .text-wrapper {
  color: var(--purple-700);
}

.badge.pill-outline.indigo .text-wrapper {
  color: var(--indigo-700);
}

.badge.pill-outline.gray-blue .text-wrapper {
  color: var(--gray-blue700);
}

.badge.pill-outline.orange .text-wrapper {
  color: var(--orange-700);
}

.badge.pill-outline.brand .text-wrapper {
  color: var(--brand-700);
}

.badge.badge-color.gray-blue .text-wrapper {
  color: var(--gray-blue700);
}

.badge.pill-outline.success .text-wrapper {
  color: var(--success-700);
}

.badge.gray .text-3 {
  color: var(--gray-700);
}

.badge.size-0-sm .text-3 {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
}

.badge.warning .text-3 {
  color: var(--warning-700);
}

.badge.pill-color .text-3 {
  margin-top: -1px;
}

.badge.purple .text-3 {
  color: var(--purple-700);
}

.badge.blue .text-3 {
  color: var(--blue-700);
}

.badge.brand .text-3 {
  color: var(--brand-700);
}

.badge.pill-outline .text-3 {
  margin-top: -1.5px;
}

.badge.error .text-3 {
  color: var(--error-700);
}

.badge.blue-light .text-3 {
  color: var(--blue-light700);
}

.badge.indigo .text-3 {
  color: var(--indigo-700);
}

.badge.gray-blue .text-3 {
  color: var(--gray-blue700);
}

.badge.size-0-md .text-3 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.badge-modern .text-3 {
  margin-top: -1px;
}

.badge.size-0-lg .text-3 {
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
}

.badge.pink .text-3 {
  color: var(--pink-700);
}

.badge.badge-color .text-3 {
  margin-top: -1px;
}

.badge.orange .text-3 {
  color: var(--orange-700);
}

.badge.success .text-3 {
  color: var(--success-700);
}

.badge.blue-gray .text-3 {
  color: var(--gray-blue700);
}

.table-header {
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.table-header .text-4 {
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.table-header .help-icon {
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: relative;
}

.table-header .instance-node-2 {
  width: 16px !important;
  height: 16px !important;
  position: relative !important;
}

.table-header .disabled {
  color: var(--gray-500);
}

.table-header .default {
  color: var(--gray-600);
}

.table-header .hover {
  color: var(--gray-700);
}

.table-header .up.state-hover {
  background-image: url("help-circle-8.a3a4f7d1.svg");
}

.table-header .down.state-default {
  background-image: url("help-circle-3.f9358527.svg");
}

.table-header .down.state-disabled {
  background-image: url("help-circle-5.f59c268c.svg");
}

.table-header .state-default.false {
  background-image: url("help-circle.08689d40.svg");
}

.table-header .up.state-disabled {
  background-image: url("help-circle-6.da29aa70.svg");
}

.table-header .up.state-default {
  background-image: url("help-circle-4.7847949b.svg");
}

.table-header .false.state-disabled {
  background-image: url("help-circle-1.811c6225.svg");
}

.table-header .state-hover.false {
  background-image: url("help-circle-2.73286bcc.svg");
}

.table-header .state-hover.down {
  background-image: url("help-circle-7.55001628.svg");
}

.transaction-row {
  border-bottom: 1px solid #e0e0e0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.loading {
  color: #0000;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%) 0 0 / 400%;
  animation: 1.2s ease-in-out infinite loading;
}

@keyframes loading {
  0% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinning {
  animation: 1s linear spin;
}

.active {
  background-color: var(--gray-700);
  color: #fff;
}

.symbol-circle {
  color: #0177ff;
  background-color: #0077ff1a;
  border: 1px solid #07f3;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-family: Inter, sans-serif;
  display: flex;
}

.chart-container {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-sm);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1216px;
  height: 200px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.chart-container h3 {
  color: #333;
  border-bottom: 1px solid #e1e4e8;
  margin-bottom: 16px;
  padding-bottom: 8px;
  font-size: 1.125rem;
}

.desktop {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.desktop .header-navigation {
  z-index: 10;
  background-color: var(--basewhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 100%;
  padding: 0 4px;
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.desktop .nav-content-wrapper {
  max-width: 1224px;
  margin: 0 auto;
}

.desktop .container {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 72px;
  display: flex;
  position: relative;
}

.desktop .content {
  flex: none;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.desktop .logo {
  width: 116px;
  height: 24px;
  position: relative;
}

.desktop .vector {
  width: 78px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 36px;
}

.desktop .logomark-instance {
  width: 25px !important;
  height: 17px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.desktop .div-2 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.desktop .nav-item-base {
  background-color: var(--gray-50);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .div-3 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.desktop .bar-chart {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.desktop .text-5 {
  color: #121315;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.desktop .content-wrapper {
  background-color: var(--basewhite);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .design-component-instance-node {
  position: relative !important;
}

.desktop .dropdown {
  flex-direction: column;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.desktop .avatar-2 {
  background-color: #f9fafb;
  border-radius: 200px;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Aeonik-Medium, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  top: 7px;
  left: 13px;
}

.desktop .contrast-border-2 {
  opacity: .08;
  border: .75px solid #121315;
  border-radius: 200px;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.desktop .main {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  padding: 48px 0 96px;
  display: inline-flex;
  position: relative;
}

.desktop .container-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.desktop .page-header-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  max-width: 1280px;
  display: inline-flex;
  position: relative;
}

.desktop .page-header {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.desktop .content-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px 20px;
  width: 1216px;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  min-width: 320px;
  display: flex;
  position: relative;
}

.desktop .text-6 {
  color: #121315;
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.desktop .supporting-text {
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  align-self: stretch;
  position: relative;
}

.desktop .date-picker-dropdown {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.desktop .button-group {
  border: 1px solid;
  border-color: var(--gray-300);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .button-group-base {
  border-color: var(--gray-300);
  z-index: 3;
  background-color: #fff;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 8px 16px;
  display: inline-flex;
  position: relative;
}

.desktop .text-7 {
  color: var(--gray-800);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .div-wrapper {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 2;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.desktop .text-8 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .button-group-base-2 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 1;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.desktop .button-group-base-3 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 0;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.desktop .button {
  all: unset;
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  background-color: #121315;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 14px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .button:hover {
  background-color: #404143;
}

.desktop .arrow-left {
  width: 20px;
  height: 20px;
  position: relative;
}

.desktop .text-padding {
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  display: inline-flex;
  position: relative;
}

.button-group-base.active, .div-wrapper.active, .button-group-base-2.active, .button-group-base-3.active, .button-group-base:hover, .div-wrapper:hover, .button-group-base-2:hover, .button-group-base-3:hover {
  background-color: #f9fafb;
}

.desktop .text-9 {
  color: var(--basewhite);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .divider {
  width: 1216px;
  height: 1px;
  position: relative;
}

.desktop .sections-wrapper {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  max-width: 1280px;
  display: inline-flex;
  position: relative;
}

.desktop .sections {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 1224px;
  display: flex;
  position: relative;
}

.desktop .section {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .content-3 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.desktop .metric-item {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 388px;
  padding: 24px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.desktop .metric-item:hover {
  background-color: #f9fafb;
}

.desktop .featured-icon {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 10px;
  width: 48px;
  height: 48px;
  position: relative;
}

.desktop .img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.desktop .heading-and-number {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .heading {
  color: #475466;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.desktop .number-and-badge {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .number {
  color: #121315;
  letter-spacing: -.72px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  position: relative;
}

.desktop .number-2 {
  color: #121315;
  font-family: var(--display-md-semibold-font-family);
  font-size: var(--display-md-semibold-font-size);
  font-style: var(--display-md-semibold-font-style);
  font-weight: var(--display-md-semibold-font-weight);
  letter-spacing: var(--display-md-semibold-letter-spacing);
  line-height: var(--display-md-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .table {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-sm);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1216px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.desktop .card-header {
  background-color: var(--basewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting-wrapper {
  z-index: 1;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 20px 24px 0;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting-2 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.desktop .text-and-badge {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .text-10 {
  color: var(--gray-900);
  font-family: var(--text-lg-semibold-font-family);
  font-size: var(--text-lg-semibold-font-size);
  font-style: var(--text-lg-semibold-font-style);
  font-weight: var(--text-lg-semibold-font-weight);
  letter-spacing: var(--text-lg-semibold-letter-spacing);
  line-height: var(--text-lg-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .badge-instance {
  background-color: #0077ff1a !important;
  border-color: #07f3 !important;
  flex: none !important;
}

.desktop .badge-2 {
  color: #07f !important;
}

.desktop .p {
  -webkit-line-clamp: 1;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.desktop .divider-2 {
  z-index: 0;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.desktop .content-4 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .column {
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
  display: flex;
  position: relative;
}

.desktop .table-header-cell {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 44px;
  padding: 12px 24px;
  display: flex;
  position: relative;
}

.desktop .table-header-2 {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.desktop .text-11 {
  color: var(--gray-600);
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .table-cell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.desktop .ellipse {
  object-fit: cover;
  width: 40px;
  height: 40px;
  position: relative;
}

.desktop .div-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.desktop .text-12 {
  color: var(--gray-900);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .supporting-text-2 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .column-2 {
  flex-direction: column;
  align-items: flex-start;
  width: 68px;
  display: flex;
  position: relative;
}

.desktop .table-header-cell-2 {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  width: 68px;
  height: 44px;
  position: relative;
}

.desktop .arrow-left-wrapper {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-items: center;
  height: 72px;
  padding: 16px 24px;
  display: inline-flex;
  position: relative;
}

.desktop .table-header-cell-3 {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  width: 100%;
  height: 44px;
  position: relative;
}

.desktop .column-3 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.desktop .table-header-instance {
  flex: none !important;
}

.desktop .table-cell-2 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.desktop .text-13 {
  color: var(--gray-900);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .table-cell-3 {
  align-self: stretch;
  width: 100%;
  height: 72px;
  position: relative;
}

.desktop .pagination {
  border-color: var(--gray-200);
  border-top-style: solid;
  border-top-width: 1px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  padding: 12px 24px 16px;
  display: flex;
  position: relative;
}

.desktop .details {
  color: var(--gray-700);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .actions {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.desktop .buttons-button {
  all: unset;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-300);
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .buttons-button:hover {
  background-color: #f9fafb;
}

.desktop .text-14 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.mini-chart {
  width: 100%;
  height: 50px;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0, #f8f8f8, #f0f0f0) 0 0 / 200% 100%;
  border-radius: 4px;
  animation: 1.5s infinite shimmer;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.dropdown-menu {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 150px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 2px 8px #1018280d;
}

.dropdown-menu button {
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
  padding: 16px;
}

.dropdown-menu button:hover {
  background-color: #f9fafb;
}

.no-transactions {
  text-align: center;
  box-sizing: border-box;
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 2rem;
  font-family: Inter, sans-serif;
  display: flex;
}

.no-transactions-message {
  text-align: center;
  grid-column: 1 / -1;
  align-self: center;
  padding: 20px;
  font-family: Inter, sans-serif;
}

.no-transactions-container {
  grid-column: 1 / -1;
  place-items: center;
  height: 100px;
  font-family: Inter, sans-serif;
  display: grid;
}

.desktop {
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.desktop .header-navigation {
  background-color: var(--basewhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1224px;
  padding: 0 4px;
  display: flex;
  position: relative;
}

.desktop .nav-content-wrapper {
  max-width: 1224px;
  margin: 0 auto;
}

.desktop .container {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 72px;
  display: flex;
  position: relative;
}

.desktop .content {
  flex: none;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.desktop .logo {
  width: 116px;
  height: 24px;
  position: relative;
}

.desktop .vector {
  width: 78px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 36px;
}

.desktop .logomark-instance {
  width: 25px !important;
  height: 17px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.desktop .div-2 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.desktop .nav-item-base {
  background-color: var(--gray-50);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .div-3 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.desktop .bar-chart {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.desktop .text-5 {
  color: #121315;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.desktop .content-wrapper {
  background-color: var(--basewhite);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .design-component-instance-node {
  position: relative !important;
}

.desktop .dropdown {
  flex-direction: column;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.desktop .avatar-2 {
  background-color: #f9fafb;
  border-radius: 200px;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .text-wrapper-2 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Aeonik-Medium, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  top: 7px;
  left: 13px;
}

.desktop .contrast-border-2 {
  opacity: .08;
  border: .75px solid #121315;
  border-radius: 200px;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.desktop .main {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  padding: 48px 0 96px;
  display: inline-flex;
  position: relative;
}

.desktop .container-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.desktop .page-header-wrapper {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  max-width: 1280px;
  display: inline-flex;
  position: relative;
}

.desktop .page-header {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.desktop .content-2 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px 20px;
  width: 1216px;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  min-width: 320px;
  display: flex;
  position: relative;
}

.desktop .text-6 {
  color: #121315;
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.desktop .supporting-text {
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  align-self: stretch;
  position: relative;
}

.desktop .date-picker-dropdown {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.desktop .button-group {
  border: 1px solid;
  border-color: var(--gray-300);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .button-group-base {
  border-color: var(--gray-300);
  z-index: 3;
  background-color: #fff;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 8px 16px;
  display: inline-flex;
  position: relative;
}

.desktop .text-7 {
  color: var(--gray-800);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .div-wrapper {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 2;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.desktop .text-8 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .button-group-base-2 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 1;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.desktop .button-group-base-3 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 0;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.desktop .button {
  all: unset;
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  background-color: #121315;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 14px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .button:hover {
  background-color: #404143;
}

.desktop .arrow-left {
  width: 20px;
  height: 20px;
  position: relative;
}

.desktop .text-padding {
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  display: inline-flex;
  position: relative;
}

.button-group-base.active, .div-wrapper.active, .button-group-base-2.active, .button-group-base-3.active, .button-group-base:hover, .div-wrapper:hover, .button-group-base-2:hover, .button-group-base-3:hover {
  background-color: #f9fafb;
}

.desktop .text-9 {
  color: var(--basewhite);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .divider {
  width: 1216px;
  height: 1px;
  position: relative;
}

.desktop .sections-wrapper {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  max-width: 1280px;
  display: inline-flex;
  position: relative;
}

.desktop .sections {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 1224px;
  display: flex;
  position: relative;
}

.desktop .section {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .content-3 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.desktop .metric-item {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 388px;
  padding: 24px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.desktop .metric-item:hover {
  background-color: #f9fafb;
}

.desktop .featured-icon {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 10px;
  width: 48px;
  height: 48px;
  position: relative;
}

.desktop .img {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.desktop .heading-and-number {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .heading {
  color: #475466;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.desktop .number-and-badge {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .number {
  color: #121315;
  letter-spacing: -.72px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  position: relative;
}

.desktop .number-2 {
  color: #121315;
  font-family: var(--display-md-semibold-font-family);
  font-size: var(--display-md-semibold-font-size);
  font-style: var(--display-md-semibold-font-style);
  font-weight: var(--display-md-semibold-font-weight);
  letter-spacing: var(--display-md-semibold-letter-spacing);
  line-height: var(--display-md-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .table {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-sm);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1216px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.desktop .card-header {
  background-color: var(--basewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting-wrapper {
  z-index: 1;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 20px 24px 0;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting-2 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.desktop .text-and-badge {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .text-10 {
  color: var(--gray-900);
  font-family: var(--text-lg-semibold-font-family);
  font-size: var(--text-lg-semibold-font-size);
  font-style: var(--text-lg-semibold-font-style);
  font-weight: var(--text-lg-semibold-font-weight);
  letter-spacing: var(--text-lg-semibold-letter-spacing);
  line-height: var(--text-lg-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .badge-instance {
  background-color: #0077ff1a !important;
  border-color: #07f3 !important;
  flex: none !important;
}

.desktop .badge-2 {
  color: #07f !important;
}

.desktop .p {
  -webkit-line-clamp: 1;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.desktop .divider-2 {
  z-index: 0;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.desktop .content-4 {
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.desktop .column {
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
  display: flex;
  position: relative;
}

.desktop .table-header-cell {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 44px;
  padding: 12px 24px;
  display: flex;
  position: relative;
}

.desktop .table-header-2 {
  flex: none;
  align-items: center;
  gap: 4px;
  display: inline-flex;
  position: relative;
}

.desktop .text-11 {
  color: var(--gray-600);
  font-family: var(--text-xs-medium-font-family);
  font-size: var(--text-xs-medium-font-size);
  font-style: var(--text-xs-medium-font-style);
  font-weight: var(--text-xs-medium-font-weight);
  letter-spacing: var(--text-xs-medium-letter-spacing);
  line-height: var(--text-xs-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .table-cell {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.desktop .ellipse {
  object-fit: cover;
  width: 40px;
  height: 40px;
  position: relative;
}

.desktop .div-4 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.desktop .text-12 {
  color: var(--gray-900);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .supporting-text-2 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .column-2 {
  flex-direction: column;
  align-items: flex-start;
  width: 68px;
  display: flex;
  position: relative;
}

.desktop .table-header-cell-2 {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  width: 68px;
  height: 44px;
  position: relative;
}

.desktop .arrow-left-wrapper {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-items: center;
  height: 72px;
  padding: 16px 24px;
  display: inline-flex;
  position: relative;
}

.desktop .table-header-cell-3 {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  width: 100%;
  height: 44px;
  position: relative;
}

.desktop .column-3 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.desktop .table-header-instance {
  flex: none !important;
}

.desktop .table-cell-2 {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  width: 100%;
  height: 72px;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.desktop .text-and-supporting-3 {
  flex-direction: column;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.desktop .text-13 {
  color: var(--gray-900);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.desktop .table-cell-3 {
  align-self: stretch;
  width: 100%;
  height: 72px;
  position: relative;
}

.desktop .pagination {
  border-color: var(--gray-200);
  border-top-style: solid;
  border-top-width: 1px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  padding: 12px 24px 16px;
  display: flex;
  position: relative;
}

.desktop .details {
  color: var(--gray-700);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.desktop .actions {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.desktop .buttons-button {
  all: unset;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-300);
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.desktop .buttons-button:hover {
  background-color: #f9fafb;
}

.desktop .text-14 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.mini-chart {
  width: 100%;
  height: 50px;
}

.skeleton {
  background: linear-gradient(90deg, #f0f0f0, #f8f8f8, #f0f0f0) 0 0 / 200% 100%;
  border-radius: 4px;
  animation: 1.5s infinite shimmer;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}

.dropdown-menu {
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 150px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 2px 8px #1018280d;
}

.dropdown-menu button {
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
  padding: 16px;
}

.dropdown-menu button:hover {
  background-color: #f9fafb;
}

.no-transactions {
  text-align: center;
  box-sizing: border-box;
  grid-column: 1 / -1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  padding: 2rem;
  font-family: Inter, sans-serif;
  font-weight: 400;
  display: flex;
}

.no-transactions-message {
  text-align: center;
  grid-column: 1 / -1;
  align-self: center;
  padding: 20px;
  font-family: Inter, sans-serif;
  font-weight: 400;
}

.no-transactions-container {
  grid-column: 1 / -1;
  place-items: center;
  height: 100px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  display: grid;
}

.no-data {
  background-color: #fcfcfc;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.no-data .header-navigation-2 {
  background-color: var(--basewhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1444px;
  margin-left: -2px;
  margin-right: -2px;
  display: flex;
  position: relative;
}

.no-data .container-2 {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  height: 72px;
  padding: 0 32px;
  display: flex;
  position: relative;
}

.no-data .content-5 {
  flex: none;
  align-items: center;
  gap: 40px;
  display: inline-flex;
  position: relative;
}

.no-data .logo-2 {
  width: 116px;
  height: 24px;
  position: relative;
}

.no-data .vector-2 {
  width: 78px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 36px;
}

.no-data .icon-instance-node {
  width: 25px !important;
  height: 17px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.no-data .div-5 {
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.no-data .nav-item-base-2 {
  background-color: var(--gray-50);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.no-data .div-6 {
  flex: none;
  align-items: center;
  gap: 12px;
  display: inline-flex;
  position: relative;
}

.no-data .bar-chart-01 {
  width: 24px !important;
  height: 24px !important;
  position: relative !important;
}

.no-data .text-15 {
  color: #121315;
  letter-spacing: 0;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: relative;
}

.no-data .nav-item-base-3 {
  background-color: var(--basewhite);
  border-radius: 6px;
  flex: none;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.no-data .dot-2 {
  position: relative !important;
}

.no-data .avatar-wrapper {
  flex-direction: column;
  align-items: flex-start;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.no-data .avatar-3 {
  background-color: #f9fafb;
  border-radius: 200px;
  flex: 1;
  align-self: stretch;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .text-wrapper-3 {
  color: #000;
  letter-spacing: 0;
  white-space: nowrap;
  font-family: Aeonik-Medium, Helvetica;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  position: absolute;
  top: 7px;
  left: 13px;
}

.no-data .contrast-border-3 {
  opacity: .08;
  border: .75px solid #121315;
  border-radius: 200px;
  flex: 1;
  align-self: stretch;
  position: relative;
}

.no-data .main-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 32px;
  padding: 48px 0 96px;
  display: inline-flex;
  position: relative;
}

.no-data .container-wrapper-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.no-data .container-3 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 24px;
  max-width: 1280px;
  display: inline-flex;
  position: relative;
}

.no-data .page-header-2 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.no-data .content-6 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 16px 20px;
  width: 1216px;
  display: flex;
  position: relative;
}

.no-data .text-and-supporting-4 {
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 4px;
  min-width: 320px;
  display: flex;
  position: relative;
}

.no-data .text-16 {
  color: #121315;
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.no-data .supporting-text-3 {
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  align-self: stretch;
  position: relative;
}

.no-data .button-group-wrapper {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  position: relative;
}

.no-data .button-group-2 {
  border: 1px solid;
  border-color: var(--gray-300);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 8px;
  flex: none;
  align-items: flex-start;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.no-data .button-group-base-4 {
  background-color: var(--gray-50);
  border-color: var(--gray-300);
  z-index: 3;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  margin-left: -1px;
  padding: 8px 16px;
  display: inline-flex;
  position: relative;
}

.no-data .text-17 {
  color: var(--gray-800);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.no-data .button-group-base-5 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 2;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.no-data .text-18 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.no-data .button-group-base-6 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 1;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.no-data .button-group-base-7 {
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  z-index: 0;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: 40px;
  margin-top: -1px;
  margin-bottom: -1px;
  padding: 8px 16px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
}

.no-data .button-2 {
  all: unset;
  border: 1px solid;
  border-color: var(--brand-600);
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  background-color: #121315;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 10px 14px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.no-data .refresh-ccw {
  width: 20px;
  height: 20px;
  position: relative;
}

.no-data .text-padding-2 {
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 0 2px;
  display: inline-flex;
  position: relative;
}

.no-data .text-19 {
  color: var(--basewhite);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.no-data .divider-3 {
  width: 1216px;
  height: 1px;
  position: relative;
}

.no-data .container-4 {
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  max-width: 1280px;
  display: inline-flex;
  position: relative;
}

.no-data .sections-2 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 1224px;
  display: flex;
  position: relative;
}

.no-data .section-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 24px;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .content-7 {
  flex-wrap: wrap;
  flex: none;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: inline-flex;
  position: relative;
}

.no-data .metric-item-2 {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 388px;
  padding: 24px;
  transition: all .2s;
  display: flex;
  position: relative;
}

.no-data .img-wrapper {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-xs);
  border-radius: 10px;
  width: 48px;
  height: 48px;
  position: relative;
}

.no-data .img-2 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.no-data .heading-and-number-2 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .heading-2 {
  color: #475466;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.no-data .number-wrapper {
  flex-wrap: wrap;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 16px 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .number-3 {
  color: #121315;
  letter-spacing: -.72px;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  font-family: Inter, Helvetica;
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  position: relative;
}

.no-data .number-4 {
  color: #121315;
  font-family: var(--display-md-semibold-font-family);
  font-size: var(--display-md-semibold-font-size);
  font-style: var(--display-md-semibold-font-style);
  font-weight: var(--display-md-semibold-font-weight);
  letter-spacing: var(--display-md-semibold-letter-spacing);
  line-height: var(--display-md-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.no-data .table-2 {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  box-shadow: var(--shadows-shadow-sm);
  border-radius: 12px;
  flex-direction: column;
  flex: none;
  align-items: center;
  width: 1216px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.no-data .card-header-2 {
  background-color: var(--basewhite);
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .content-8 {
  z-index: 1;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding: 20px 24px 0;
  display: flex;
  position: relative;
}

.no-data .text-and-supporting-5 {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  gap: 4px;
  display: flex;
  position: relative;
}

.no-data .text-and-badge-2 {
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 8px;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .text-20 {
  color: var(--gray-900);
  font-family: var(--text-lg-semibold-font-family);
  font-size: var(--text-lg-semibold-font-size);
  font-style: var(--text-lg-semibold-font-style);
  font-weight: var(--text-lg-semibold-font-weight);
  letter-spacing: var(--text-lg-semibold-letter-spacing);
  line-height: var(--text-lg-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.no-data .badge-3 {
  background-color: #0077ff1a !important;
  border-color: #07f3 !important;
  flex: none !important;
}

.no-data .badge-4 {
  color: #07f !important;
}

.no-data .supporting-text-4 {
  -webkit-line-clamp: 1;
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  align-self: stretch;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
}

.no-data .divider-4 {
  z-index: 0;
  align-self: stretch;
  width: 100%;
  height: 1px;
  position: relative;
}

.no-data .column-wrapper {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.no-data .column-4 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  display: flex;
  position: relative;
}

.no-data .table-header-wrapper {
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 44px;
  padding: 12px 24px;
  display: flex;
  position: relative;
}

.no-data .table-header-3 {
  flex: none !important;
}

.no-data .empty-state {
  flex: none;
  justify-content: center;
  align-items: center;
  width: 512px;
  padding: 48px 0;
  display: flex;
  position: relative;
}

.no-data .empty-state-2 {
  flex-direction: column;
  flex: 1;
  align-items: center;
  gap: 24px;
  display: flex;
  position: relative;
}

.no-data .content-9 {
  flex-direction: column;
  flex: none;
  align-items: center;
  gap: 16px;
  display: inline-flex;
  position: relative;
}

.no-data .group {
  width: 152px;
  height: 118px;
  position: relative;
}

.no-data .illustration {
  height: 118px;
  position: relative;
}

.no-data .overlap {
  width: 127px;
  height: 116px;
  position: absolute;
  top: 0;
  left: 11px;
}

.no-data .overlap-group {
  width: 121px;
  height: 116px;
  position: absolute;
  top: 0;
  left: 0;
}

.no-data .background-circle {
  background-color: var(--gray-200);
  border-radius: 52px;
  width: 104px;
  height: 104px;
  position: absolute;
  top: 0;
  left: 13px;
}

.no-data .cloud {
  box-shadow: var(--shadows-shadow-xl);
  background-image: url("background.b12a7d35.svg");
  background-size: 100% 100%;
  width: 112px;
  height: 64px;
  position: absolute;
  top: 16px;
  left: 9px;
}

.no-data .overlap-group-2 {
  height: 64px;
  position: relative;
}

.no-data .circle {
  background: linear-gradient(#d0d5dd 0%, #fff0 35.07%);
  border-radius: 25.6px;
  width: 51px;
  height: 51px;
  position: absolute;
  top: 13px;
  left: 0;
}

.no-data .circle-2 {
  background: linear-gradient(#d0d5dd 0%, #fff0 35.07%);
  border-radius: 32px;
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  left: 26px;
}

.no-data .circle-3 {
  background: linear-gradient(#d0d5dd 0%, #fff0 35.07%);
  border-radius: 22.4px;
  width: 45px;
  height: 45px;
  position: absolute;
  top: 19px;
  left: 67px;
}

.no-data .circle-4 {
  background-color: var(--gray-100);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 14px;
  left: 5px;
}

.no-data .circle-5 {
  background-color: var(--gray-100);
  border-radius: 7px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 102px;
  left: 0;
}

.no-data .circle-6 {
  background-color: var(--gray-100);
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 119px;
}

.no-data .circle-7 {
  background-color: var(--gray-100);
  border-radius: 7px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 28px;
  left: 138px;
}

.no-data .text-and-supporting-6 {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 352px;
  display: flex;
  position: relative;
}

.no-data .text-21 {
  color: var(--gray-900);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  text-align: center;
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.no-data .supporting-text-5 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  text-align: center;
  width: 244px;
  position: relative;
}

.no-data .pagination-2 {
  border-color: var(--gray-200);
  border-top-style: solid;
  border-top-width: 1px;
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  margin-left: -1px;
  margin-right: -1px;
  padding: 12px 24px 16px;
  display: flex;
  position: relative;
}

.no-data .details-2 {
  color: var(--gray-700);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.no-data .actions-2 {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  display: flex;
  position: relative;
}

.no-data .buttons-button-2 {
  all: unset;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-300);
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  transition: all .2s;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.no-data .text-22 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.login {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.login .content-wrapper {
  box-sizing: border-box;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.login .content-5 {
  flex-direction: column;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 360px;
  display: flex;
  position: relative;
}

.login .header {
  background-color: #0000;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 64px;
  width: 100%;
  display: flex;
  position: relative;
}

.login .logo {
  width: 116px;
  height: 24px;
  position: relative;
}

.login .vector {
  width: 78px;
  height: 19px;
  position: absolute;
  top: 3px;
  left: 36px;
}

.login .logomark-instance {
  width: 25px !important;
  height: 17px !important;
  position: absolute !important;
  top: 2px !important;
  left: 0 !important;
}

.login .text-and-supporting {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.login .p {
  color: var(--gray-900);
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  text-align: center;
  align-self: stretch;
  margin-top: -1px;
  position: relative;
}

.login .supporting-text-2 {
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  text-align: center;
  align-self: stretch;
  position: relative;
}

.login .content-6 {
  border-radius: 16px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  display: flex;
  position: relative;
}

.login .form {
  border-radius: 9px;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
}

.login .input-field-instance {
  background-color: #f4f4f5;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
}

.login .design-component-instance-node {
  box-shadow: unset !important;
  border-radius: 16px !important;
}

.login .input-field-2 {
  background-color: #f4f4f5;
  height: 62px;
  box-shadow: unset !important;
  border: 1px solid #f4f4f5 !important;
  border-radius: 16px !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 10px 15px !important;
  font-size: 16px !important;
  transition: border-color .3s !important;
}

.login .input-field-2:focus {
  border-color: #007bff !important;
  outline: none !important;
}

.login .actions {
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  display: flex;
  position: relative;
}

.login .buttons-button-instance {
  justify-content: center;
  align-items: center;
  font-weight: 500;
  box-shadow: none !important;
  background-color: #121315 !important;
  border: none !important;
  outline: none !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  display: flex !important;
}

.login .footer-links {
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 52px;
  display: flex;
  position: relative;
}

.login .row {
  flex: none;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  gap: 4px;
  width: 100%;
  display: flex;
  position: relative;
}

.login .text-14 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  white-space: nowrap;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.login .buttons-button-2 {
  color: #121315 !important;
}

.login .buttons-button-3 {
  flex: none !important;
}

.login .input-field-instance {
  border: 1px solid #f4f4f5;
  border-radius: 5px;
  height: 62px;
  margin-bottom: 16px;
  padding: 10px 15px;
  font-size: 16px;
  transition: border-color .3s;
}

.login .input-field-instance:focus {
  border-color: #007bff;
  outline: none;
}

.login .buttons-button-instance {
  color: #fff;
  cursor: pointer;
  background-color: #007bff;
  border-radius: 14px;
  height: 58px;
  padding: 10px 15px;
  font-size: 16px;
  transition: background-color .3s;
}

.login .buttons-button-instance:hover {
  background-color: #0056b3;
}

.error-message {
  color: #ca2240;
  text-align: center;
  background-color: #fef3f2;
  height: 38px;
  margin: 10px 0;
  font-family: Inter-SemiBold, Helvetica;
  font-size: 14px;
  border-radius: 16px !important;
  flex: none !important;
  align-self: stretch !important;
  width: 100% !important;
  margin-bottom: 10px !important;
  padding: 10px 15px !important;
}

.success-message {
  color: #1ec17e;
  text-align: center;
  background-color: #eafff6;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  margin: 10px 0;
  padding: 10px 15px;
  font-family: Inter-SemiBold, Helvetica;
  font-size: 14px;
  display: flex;
}

/*# sourceMappingURL=index.b2f0e8fc.css.map */
