.no-data {
  align-items: center;
  background-color: #fcfcfc;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.no-data .header-navigation-2 {
  align-items: center;
  background-color: var(--basewhite);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-left: -2px;
  margin-right: -2px;
  position: relative;
  width: 1444px;
}

.no-data .container-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  height: 72px;
  justify-content: space-between;
  max-width: 1280px;
  padding: 0px 32px;
  position: relative;
  width: 100%;
}

.no-data .content-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 40px;
  position: relative;
}

.no-data .logo-2 {
  height: 24px;
  position: relative;
  width: 116px;
}

.no-data .vector-2 {
  height: 19px;
  left: 36px;
  position: absolute;
  top: 3px;
  width: 78px;
}

.no-data .icon-instance-node {
  height: 17px !important;
  left: 0 !important;
  position: absolute !important;
  top: 2px !important;
  width: 25px !important;
}

.no-data .div-5 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.no-data .nav-item-base-2 {
  align-items: center;
  background-color: var(--gray-50);
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
}

.no-data .div-6 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.no-data .bar-chart-01 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.no-data .text-15 {
  color: #121315;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .nav-item-base-3 {
  align-items: center;
  background-color: var(--basewhite);
  border-radius: 6px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease;
}

.no-data .dot-2 {
  position: relative !important;
}

.no-data .avatar-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 40px;
  position: relative;
  width: 40px;
}

.no-data .avatar-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f9fafb;
  border-radius: 200px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}

.no-data .text-wrapper-3 {
  color: #000000;
  font-family: "Aeonik-Medium", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 13px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.no-data .contrast-border-3 {
  align-self: stretch;
  border: 0.75px solid;
  border-color: #121315;
  border-radius: 200px;
  flex: 1;
  flex-grow: 1;
  opacity: 0.08;
  position: relative;
}

.no-data .main-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  padding: 48px 0px 96px;
  position: relative;
}

.no-data .container-wrapper-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.no-data .container-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  max-width: 1280px;
  position: relative;
}

.no-data .page-header-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.no-data .content-6 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 16px 20px;
  justify-content: center;
  position: relative;
  width: 1216px;
}

.no-data .text-and-supporting-4 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  min-width: 320px;
  position: relative;
}

.no-data .text-16 {
  align-self: stretch;
  color: #121315;
  font-family: var(--display-sm-semibold-font-family);
  font-size: var(--display-sm-semibold-font-size);
  font-style: var(--display-sm-semibold-font-style);
  font-weight: var(--display-sm-semibold-font-weight);
  letter-spacing: var(--display-sm-semibold-letter-spacing);
  line-height: var(--display-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
}

.no-data .supporting-text-3 {
  align-self: stretch;
  color: var(--gray-600);
  font-family: var(--text-md-regular-font-family);
  font-size: var(--text-md-regular-font-size);
  font-style: var(--text-md-regular-font-style);
  font-weight: var(--text-md-regular-font-weight);
  letter-spacing: var(--text-md-regular-letter-spacing);
  line-height: var(--text-md-regular-line-height);
  position: relative;
}

.no-data .button-group-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.no-data .button-group-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 8px;
  box-shadow: var(--shadows-shadow-xs);
  display: inline-flex;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease;
}

.no-data .button-group-base-4 {
  align-items: center;
  background-color: var(--gray-50);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-left: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  z-index: 3;
}

.no-data .text-17 {
  color: var(--gray-800);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .button-group-base-5 {
  align-items: center;
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.2s ease;
  z-index: 2;
}

.no-data .text-18 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .button-group-base-6 {
  align-items: center;
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.2s ease;
  z-index: 1;
}

.no-data .button-group-base-7 {
  align-items: center;
  background-color: var(--basewhite);
  border-color: var(--gray-300);
  border-right-style: solid;
  border-right-width: 1px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  margin-bottom: -1px;
  margin-top: -1px;
  min-height: 40px;
  padding: 8px 16px;
  position: relative;
  transition: all 0.2s ease;
  z-index: 0;
}

.no-data .button-2 {
  all: unset;
  align-items: center;
  background-color: #121315;
  border: 1px solid;
  border-color: var(--brand-600);
  border-radius: 8px;
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 10px 14px;
  position: relative;
  transition: all 0.2s ease;
}

.no-data .refresh-ccw {
  height: 20px;
  position: relative;
  width: 20px;
}

.no-data .text-padding-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0px 2px;
  position: relative;
}

.no-data .text-19 {
  color: var(--basewhite);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .divider-3 {
  height: 1px;
  position: relative;
  width: 1216px;
}

.no-data .container-4 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 64px;
  justify-content: center;
  max-width: 1280px;
  position: relative;
}

.no-data .sections-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 1224px;
}

.no-data .section-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.no-data .content-7 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: center;
  position: relative;
}

.no-data .metric-item-2 {
  align-items: flex-start;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  box-shadow: var(--shadows-shadow-xs);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  position: relative;
  transition: all 0.2s ease;
  width: 388px;
}

.no-data .img-wrapper {
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 10px;
  box-shadow: var(--shadows-shadow-xs);
  height: 48px;
  position: relative;
  width: 48px;
}

.no-data .img-2 {
  height: 24px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 24px;
}

.no-data .heading-and-number-2 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.no-data .heading-2 {
  align-self: stretch;
  color: #475466;
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  margin-top: -1px;
  position: relative;
}

.no-data .number-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 16px 12px;
  position: relative;
  width: 100%;
}

.no-data .number-3 {
  color: #121315;
  font-family: "Inter", Helvetica;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.72px;
  line-height: 44px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .number-4 {
  color: #121315;
  font-family: var(--display-md-semibold-font-family);
  font-size: var(--display-md-semibold-font-size);
  font-style: var(--display-md-semibold-font-style);
  font-weight: var(--display-md-semibold-font-weight);
  letter-spacing: var(--display-md-semibold-letter-spacing);
  line-height: var(--display-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .table-2 {
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-200);
  border-radius: 12px;
  box-shadow: var(--shadows-shadow-sm);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 1216px;
}

.no-data .card-header-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--basewhite);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.no-data .content-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  padding: 20px 24px 0px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.no-data .text-and-supporting-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  justify-content: center;
  position: relative;
}

.no-data .text-and-badge-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.no-data .text-20 {
  color: var(--gray-900);
  font-family: var(--text-lg-semibold-font-family);
  font-size: var(--text-lg-semibold-font-size);
  font-style: var(--text-lg-semibold-font-style);
  font-weight: var(--text-lg-semibold-font-weight);
  letter-spacing: var(--text-lg-semibold-letter-spacing);
  line-height: var(--text-lg-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .badge-3 {
  background-color: #0077ff1a !important;
  border-color: #0077ff33 !important;
  flex: 0 0 auto !important;
}

.no-data .badge-4 {
  color: #0077ff !important;
}

.no-data .supporting-text-4 {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  align-self: stretch;
  color: var(--gray-600);
  display: -webkit-box;
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
}

.no-data .divider-4 {
  align-self: stretch;
  height: 1px;
  position: relative;
  width: 100%;
  z-index: 0;
}

.no-data .column-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
  width: 100%;
}

.no-data .column-4 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.no-data .table-header-wrapper {
  align-items: center;
  align-self: stretch;
  background-color: var(--gray-50);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: var(--gray-200);
  display: flex;
  gap: 12px;
  height: 44px;
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.no-data .table-header-3 {
  flex: 0 0 auto !important;
}

.no-data .empty-state {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 48px 0px;
  position: relative;
  width: 512px;
}

.no-data .empty-state-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.no-data .content-9 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.no-data .group {
  height: 118px;
  position: relative;
  width: 152px;
}

.no-data .illustration {
  height: 118px;
  position: relative;
}

.no-data .overlap {
  height: 116px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 127px;
}

.no-data .overlap-group {
  height: 116px;
  left: 0;
  position: absolute;
  top: 0;
  width: 121px;
}

.no-data .background-circle {
  background-color: var(--gray-200);
  border-radius: 52px;
  height: 104px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 104px;
}

.no-data .cloud {
  background-image: url(../../../static/img/background.svg);
  background-size: 100% 100%;
  box-shadow: var(--shadows-shadow-xl);
  height: 64px;
  left: 9px;
  position: absolute;
  top: 16px;
  width: 112px;
}

.no-data .overlap-group-2 {
  height: 64px;
  position: relative;
}

.no-data .circle {
  background: linear-gradient(180deg, rgb(207.67, 212.89, 220.73) 0%, rgba(255, 255, 255, 0) 35.07%);
  border-radius: 25.6px;
  height: 51px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 51px;
}

.no-data .circle-2 {
  background: linear-gradient(180deg, rgb(207.67, 212.89, 220.73) 0%, rgba(255, 255, 255, 0) 35.07%);
  border-radius: 32px;
  height: 64px;
  left: 26px;
  position: absolute;
  top: 0;
  width: 64px;
}

.no-data .circle-3 {
  background: linear-gradient(180deg, rgb(207.67, 212.89, 220.73) 0%, rgba(255, 255, 255, 0) 35.07%);
  border-radius: 22.4px;
  height: 45px;
  left: 67px;
  position: absolute;
  top: 19px;
  width: 45px;
}

.no-data .circle-4 {
  background-color: var(--gray-100);
  border-radius: 5px;
  height: 10px;
  left: 5px;
  position: absolute;
  top: 14px;
  width: 10px;
}

.no-data .circle-5 {
  background-color: var(--gray-100);
  border-radius: 7px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 102px;
  width: 14px;
}

.no-data .circle-6 {
  background-color: var(--gray-100);
  border-radius: 4px;
  height: 8px;
  left: 119px;
  position: absolute;
  top: 4px;
  width: 8px;
}

.no-data .circle-7 {
  background-color: var(--gray-100);
  border-radius: 7px;
  height: 14px;
  left: 138px;
  position: absolute;
  top: 28px;
  width: 14px;
}

.no-data .text-and-supporting-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  max-width: 352px;
  position: relative;
  width: 100%;
}

.no-data .text-21 {
  color: var(--gray-900);
  font-family: var(--text-md-semibold-font-family);
  font-size: var(--text-md-semibold-font-size);
  font-style: var(--text-md-semibold-font-style);
  font-weight: var(--text-md-semibold-font-weight);
  letter-spacing: var(--text-md-semibold-letter-spacing);
  line-height: var(--text-md-semibold-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.no-data .supporting-text-5 {
  color: var(--gray-600);
  font-family: var(--text-sm-regular-font-family);
  font-size: var(--text-sm-regular-font-size);
  font-style: var(--text-sm-regular-font-style);
  font-weight: var(--text-sm-regular-font-weight);
  letter-spacing: var(--text-sm-regular-letter-spacing);
  line-height: var(--text-sm-regular-line-height);
  position: relative;
  text-align: center;
  width: 244px;
}

.no-data .pagination-2 {
  align-items: center;
  align-self: stretch;
  border-color: var(--gray-200);
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  justify-content: center;
  margin-left: -1px;
  margin-right: -1px;
  padding: 12px 24px 16px;
  position: relative;
  width: 100%;
}

.no-data .details-2 {
  color: var(--gray-700);
  font-family: var(--text-sm-medium-font-family);
  font-size: var(--text-sm-medium-font-size);
  font-style: var(--text-sm-medium-font-style);
  font-weight: var(--text-sm-medium-font-weight);
  letter-spacing: var(--text-sm-medium-letter-spacing);
  line-height: var(--text-sm-medium-line-height);
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.no-data .actions-2 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.no-data .buttons-button-2 {
  all: unset;
  align-items: center;
  background-color: var(--basewhite);
  border: 1px solid;
  border-color: var(--gray-300);
  border-radius: 8px;
  box-shadow: var(--shadows-shadow-xs);
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  justify-content: center;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  transition: all 0.2s ease;
}

.no-data .text-22 {
  color: var(--gray-700);
  font-family: var(--text-sm-semibold-font-family);
  font-size: var(--text-sm-semibold-font-size);
  font-style: var(--text-sm-semibold-font-style);
  font-weight: var(--text-sm-semibold-font-weight);
  letter-spacing: var(--text-sm-semibold-letter-spacing);
  line-height: var(--text-sm-semibold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
